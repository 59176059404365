import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import {Lightbox} from 'react-modal-image';
import getQuestionText from '../../functions/getQuestionText';

class QuestionDropdown extends Component {
  constructor (props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      lightboxOpen: false
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleLightbox = this.toggleLightbox.bind(this);
  }

  toggleDropdown () {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  toggleLightbox () {
    this.setState(prevState => ({
      lightboxOpen: !prevState.lightboxOpen
    }));
  }

  render () {
    const {dropdownOpen, lightboxOpen} = this.state;
    const {poll, questionId, questions, onChange} = this.props;

    const options = [];
    if (poll.comments.length > 0) {
      options.push({
        key: 'overview',
        head: 'Fazit und Übersicht der Kommentare',
        text: 'Wie schneidet mein Produkt im Vergleich der Benchmarks ab?'
      });
    } else if (poll.marketSegment) {
      options.push({
        key: 'overview',
        head: 'Übersicht Produkttest',
        text: 'Wie schneidet mein Produkt im Vergleich der Benchmarks ab?'
      });
    }

    const offset = options.length;
    let currentIndex = 0;
    questions.forEach((q, i) => {
      options.push({
        key: q._id,
        head: `${i + 1}. Frage`,
        text: getQuestionText(q.text),
        img: q.imageurl
      });
      if (questionId === q._id) {
        currentIndex = i + offset;
      }
    });

    const current = options[currentIndex];

    return (
      <>
        <Dropdown
          className="questionSelect scrollable-menu"
          isOpen={dropdownOpen}
          toggle={this.toggleDropdown}
          direction="down"
        >
          <DropdownToggle className="dropdownToggle">
            <div>
              <span className="selectedQuestionHeader">
                {current.head}
              </span>
              <span className="selectedQuestionText">{current.text}</span>
            </div>
            {current.img &&
              <img
                alt=""
                src={current.img}
                onClick={e => {
                  e.stopPropagation();
                  this.toggleLightbox();
                }}
              />
            }
          </DropdownToggle>
          <DropdownMenu flip={false}>
            {options.map(o => (
              <DropdownItem
                key={o.key}
                onClick={() => {
                  window.scrollTo(0, 0);
                  onChange(o.key === 'overview' ? null : o.key);
                }}
              >
                <span className="selectedQuestionHeader">{o.head}</span>
                <span className="selectedQuestionText">{o.text}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {lightboxOpen &&
          <Lightbox
            hideDownload
            hideZoom
            small={current.img}
            large={current.img}
            imageBackgroundColor="white"
            onClose={() => this.toggleLightbox()}
          />
        }
      </>
    );
  }
}

QuestionDropdown.propTypes = {
  questionId: PropTypes.string,
  poll: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

QuestionDropdown.defaultProps = {
  // questionId can be null on the overview page of commented polls
  questionId: null
};

export default QuestionDropdown;
