function massageMatrixData(submissions, question) {
    const questionId = question._id;
    const numSubmissions = submissions.length;

    const answers = {};
    question.answeroptions.forEach((ao, index) => {
        answers[ao.text] = [];
        submissions.forEach(s => {
            const answer = s[questionId][index];
            answers[ao.text].push(answer);
        });
    });

    const countAnswers = {};
    Object.entries(answers).forEach(array => {
        countAnswers[array[0]] = [];
        array[1].forEach(value => {
            if(value) {
                countAnswers[array[0]][value.answer] = 1 + (countAnswers[array[0]][value.answer] || 0);
            }
        });
    });

    const countData = {};
    Object.entries(countAnswers).forEach(array => {
        countData[array[0]] = [];
        Object.entries(array[1]).forEach((values) => {
            countData[array[0]][values[0]] = [values[1], (values[1] / numSubmissions * 100).toFixed(2)];
        });
    });


    let answerOptions = [];
    if (question.isRangeSlider) {
        for (let i = 0; i <= question.rangeMax; i++) {
            answerOptions.push(i);
        }
    } else {
        question.matrixoptions.forEach(option => {
            answerOptions.push(option.text);
        });
    }

    const data = {countData, answerOptions, numSubmissions, question};

    let dataSet = {
        labels: [],
        datasets: []
    };
    question.answeroptions.forEach(option => {
        dataSet.labels.push(option.text);
    })
    answerOptions.forEach(option => {
        let values = [];
        Object.values(countData).forEach(questionData => {
            if (questionData[option] === undefined) {
                values.push([0, 0]);
            } else {
                values.push(questionData[option]);
            }
        });
        dataSet.datasets.push({data: values, label: option});
    });

    return dataSet;
}

export default massageMatrixData;