import React from 'react';
import {Card, CardBody} from 'reactstrap';
import Loading from '../components/Loading';
import usePanelBookCharts from '../hooks/usePanelBookCharts';
import isMultiQuestion from '../functions/isMultiQuestion';

// FIXME: the questions to be displayed should be configured in the database
// mapping start poll question IDs to chart types
const selectedQuestions = {
  '61ce0e8c9b06d3677e6e42c6': 'bar',
  '61ce0eee077e7167af1581da': 'bar',
  '61ce1115077e7167af1581db': 'bar',
  '61ce11fe2ee82f6ae69d654d': 'bar',
  '61ce13982ee82f6ae69d654e': 'bar',
  '61ce13b0077e7167af1581de': 'doughnut',
  '61ce1418077e7167af1581df': 'doughnut',
  '61ce144f9b06d3677e6e42c9': 'doughnut',
  '61ce14832ee82f6ae69d654f': 'doughnut',
  '61ce15242ee82f6ae69d6550': 'bar',
  '61ce152e077e7167af1581e1': 'doughnut',
  '61ce153c9b06d3677e6e42cb': 'bar',
  '61ce155e2ee82f6ae69d6551': 'doughnut',
  '61ce1573077e7167af1581e2': 'bar',
  '61ce157d9b06d3677e6e42cc': 'bar',
  '61d422da9b06d3677e6e42f6': 'doughnut',
  '61d422f72ee82f6ae69d656f': 'bar',
  '61d4223e9b06d3677e6e42f5': 'bar'
};

const Panelbook = () => {
  const charts = usePanelBookCharts(selectedQuestions);
  return (
    <div className="page panelbook-page animated fadeIn">
      <h1>Panelbuch</h1>
      {charts ? charts.map(chart => (
        <Card key={chart.key}>
          <CardBody>
            <h3>{chart.title}</h3>
            {isMultiQuestion(chart.question) &&
              <p><i>Mehrfachnennungen möglich</i></p>}
            {chart.chart}
          </CardBody>
        </Card>
      )) : <Loading/>}
    </div>
  );
};

export default Panelbook;
