import React, {Component} from 'react';
import {Row, Col, Card, CardBody} from 'reactstrap';
import {Link} from 'react-router-dom';
import Greeting from '../components/Dashboard/Greeting';
import PollCardCarousel from '../components/Dashboard/PollCardCarousel';
import PanelBookCarousel from '../components/Dashboard/PanelBookCarousel';
import ProductCheckRadar from '../components/Charts/ProductCheckRadar';
import ProductTestForm from '../components/ProductTestForm/ProductTestForm';

import {apiUrl} from '../config';

const FIVE_DAYS = 1000 * 60 * 60 * 24 * 5;

class Dashboard extends Component {
  constructor (props) {
    super(props);

    this.state = {
      userName: null,
      numberPollsOverall: 0,
      pollsGroups: [],
      activePollsCount: 0,
      marketSegmentBenchmarks: {},
      customerBenchmarks: {}
    };
  }

  componentDidMount () {
    this.loadPolls();
  }

  loadPolls () {
    const url = `${apiUrl}/customer/polls`;
    const opts = {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
      credentials: 'include'
    };
    fetch(url, opts)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        const {polls} = data;
        const userName = data.customeraccountname;
        const marketSegment = data.customermarketsegment;
        const numberPollsOverall = polls.length;
        const activePolls = polls.filter(x => {
          const year = new Date(x.lastAnswerTimeStamp);
          if (year.getTime() > Date.now() - FIVE_DAYS) {
            return x;
          }
          return false;
        });
        const activePollsCount = activePolls.length;

        polls.sort((a, b) => b.firstAnswerTimeStamp - a.firstAnswerTimeStamp);
        const pollIdHash = {};
        const filteredPolls = polls.filter(x => {
          const ret = pollIdHash[x.pollid] === undefined;
          pollIdHash[x.pollid] = true;
          return ret;
        });

        const pollsGroups = [];
        let threePolls = [];
        for (let i = 0; i < filteredPolls.length; i++) {
          if (i % 3 === 0) {
            threePolls = [];
          }
          threePolls.push(filteredPolls[i]);
          if (i % 3 === 2 || i === activePolls.length - 1) {
            pollsGroups.push(threePolls);
          }
        }

        this.setState({
          userName,
          numberPollsOverall,
          pollsGroups,
          activePollsCount,
          closedPolls: numberPollsOverall - activePollsCount,
          marketSegment
        });
        const url = `${apiUrl}/benchmarks/customer/${data.customerid}`;
        const opts = {
          method: 'GET',
          headers: {
            Accept: 'application/json'
          },
          credentials: 'include'
        };
        fetch(url, opts)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(data => {
            this.setState({
              customerBenchmarks: data
            });
          });
        const allurl = `${apiUrl}/benchmarks/marketsegment/${marketSegment}`;
        fetch(allurl, opts)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(data => {
            console.log(data);
            this.setState({
              marketSegmentBenchmarks: data
            });
          });
      })
      .catch(error => {
        console.log('cannot load data', error);
        localStorage.removeItem('sessionId');
        document.location.href = '/';
      });
  }

  render () {
    const {
      userName,
      numberPollsOverall,
      pollsGroups,
      activePollsCount,
      closedPolls,
      customerBenchmarks,
      marketSegmentBenchmarks,
      marketSegment
    } = this.state;

    return (
      <div className="page dashboard animated fadeIn">
        <Greeting name={userName}/>
        <ProductTestForm/>
        <Row className="my-4">
          <Col>
            <h4 className="overview">Ihre aktuellen Umfragen im Überblick</h4>
            <div className="card-deck overviewPanel">
              <Card>
                Anzahl aller Umfragen: {numberPollsOverall}
              </Card>
              <Card>
                Abgeschlossene Umfragen: {closedPolls}
              </Card>
              <Card>
                Aktive Umfragen: {activePollsCount}
              </Card>
              <div className="text-right">
                <Link className="btn btn-outline-primary" to="/polls">
                  Umfragen anzeigen
                </Link>
              </div>
            </div>
            <PollCardCarousel pollsGroups={pollsGroups}/>
          </Col>
        </Row>
        {marketSegmentBenchmarks.submissionsCount > 0 && (
          <Row className="my-4">
            <Col>
              <h4 className="overview">Übersicht Produkttests</h4>
              <Card>
                <CardBody>
                  <ProductCheckRadar {...{
                    customerBenchmarks,
                    marketSegment,
                    marketSegmentBenchmarks
                  }}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Row className="my-4">
          <Col>
            <PanelBookCarousel/>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
