import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Nav, NavItem, NavLink} from 'reactstrap';
import stopwords from 'stopwords-de';
import cleanNetworkPlotString from '../../functions/cleanNetworkPlotString';
import Network from './Network';
import Wordcloud from './Wordcloud';

const Freetext = ({answers}) => {
  const [activeChart, setActiveChart] = useState(0);

  const words = [];
  answers.forEach(answer => {
    cleanNetworkPlotString(answer).split(/\.|-|,| |;|:/).forEach(word => {
      if (word.length > 2 && stopwords.indexOf(word.toLowerCase()) === -1) {
        words.push(word);
      }
    });
  });

  return (
    <div className="freetextContainer">
      <Nav pills className="freeTextPlotButton">
        <NavItem>
          <NavLink
            className={classnames({active: activeChart === 0})}
            onClick={() => setActiveChart(0)}
          >
            Wordcloud
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({active: activeChart === 1})}
            onClick={() => setActiveChart(1)}
          >
            Netzwerkanalyse
          </NavLink>
        </NavItem>
      </Nav>
      {activeChart === 0 && <Wordcloud {...{words}}/>}
      {activeChart === 1 && <Network {...{words, answers}}/>}
    </div>
  );
};

Freetext.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Freetext;
