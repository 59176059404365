import React from 'react';
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import RadioButtonGroup from './RadioButtonGroup';
import Steps from './Steps';

const PageTwo = props => {
  const {toggle, previousPage, nextPage, input} = props;
  return (
    <>
      <ModalHeader toggle={toggle}>
        Vorschau des Fragebogens an das Test-Panel
        <Steps page={2}/>
      </ModalHeader>
      <ModalBody>
        <h2>
          Vorschau des Fragebogens an das Test-Panel über die biopinio App
        </h2>
        <div className="formPreview">
          <div>
            Lieber biopinio-Nutzer,<br/>
            <br/>
            hier kannst Du <b>{input.productName}</b> von <b>{input.brand}</b>
            bewerten. Viel Spaß!<br/>
            Dein biopinio-Team<br/>
          </div>
          <div>
            1. Wie gut hat Dir das <b>Produkt insgesamt</b> auf einer Skala von
            1 (sehr gut) bis 5 (sehr schlecht) gefallen?
            <RadioButtonGroup
              count={5}
              firstLabel="(sehr gut)"
              lastLabel="(sehr schlecht)"
            />
          </div>
          <div>
            2. Gab es etwas, was Dir an dem Produkt nicht so gut gefallen hat?
            Wenn ja, kannst Du es hier beschreiben.
            (Ansonsten frei lassen und weiter.)
            <span className="preview-input"/>
          </div>
          <div>
            3. Wie gut hat Dir der <b>Geschmack</b> auf einer Skala von 1 (sehr
            gut) bis 5 (sehr schlecht) gefallen?
            <RadioButtonGroup
              count={5}
              firstLabel="(sehr gut)"
              lastLabel="(sehr schlecht)"
            />
          </div>
          <div>
            4. Wie gut hat Dir die <b>Konsistenz</b> auf einer Skala von 1 (sehr
            gut) bis 5 (sehr schlecht) gefallen?
            <RadioButtonGroup
              count={5}
              firstLabel="(sehr gut)"
              lastLabel="(sehr schlecht)"
            />
          </div>
          <div>
            5. Wie gut hat Dir das <b>Design der Verpackung</b> auf einer Skala
            von 1 (sehr gut) bis 5 (sehr schlecht) gefallen?
            <RadioButtonGroup
              count={5}
              firstLabel="(sehr gut)"
              lastLabel="(sehr schlecht)"
            />
          </div>
          <div>
            6. Wie gut hat Dir das <b>Aussehen des Produktes</b> selbst auf
            einer Skala von 1 (sehr gut) bis 5 (sehr schlecht) gefallen?
            <RadioButtonGroup
              count={5}
              firstLabel="(sehr gut)"
              lastLabel="(sehr schlecht)"
            />
          </div>
          <div>
            7. <b>{ input.productName }</b> ({input.amount}{input.unit})  von
            <b>{ input.brand }</b> kostet {input.price}€. Wie bewertest Du das
            Preis-Leistungs-Verhältnis auf einer Skala von 1 (sehr gut) bis 5
            (sehr schlecht)?
            <RadioButtonGroup
              count={5}
              firstLabel="(sehr gut)"
              lastLabel="(sehr schlecht)"
            />
          </div>
          <div>
            8. Wie wahrscheinlich ist es, dass Du <b>{ input.productName }</b>
            von <b>{ input.brand }</b> einem Freund oder Kollegen
            weiterempfehlen wirst?
            Achte darauf, dass 0 – unwahrscheinlich und 10 – äußerst
            wahrscheinlich bedeutet.
            <RadioButtonGroup
              count={10}
              firstLabel="(unwahrscheinlich)"
              lastLabel="(äußerst wahrscheinlich)"
            />
          </div>
          <div>
            9. Hier hast Du noch Platz für weiteres Feedback:
            <span className="preview-input"/>
          </div>
          <div>
            Das war es auch schon! Vielen Dank für Deine Teilnahme! <br/>
            Bis zum nächsten Mal auf biopinio!<br/>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={previousPage}>
          Zurück
        </Button>
        <Button color="primary" onClick={nextPage}>
          Weiter
        </Button>
      </ModalFooter>
    </>
  );
};

export default PageTwo;
