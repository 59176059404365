import React from 'react';
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

const PageFour = props => {
  const {toggle} = props;
  return (
    <>
      <ModalHeader toggle={toggle}>
        Vielen Dank für Ihre Anfrage
      </ModalHeader>
      <ModalBody className="page4">
        <p>
          Wir werden Ihr Anliegen schnellstmöglich bearbeiten <br/>
          und Ihnen innerhalb der nächsten 3 Tage ein <br/>
          unverbindliches Angebot per E-Mail zusenden.
        </p>
        <p>
          Haben Sie noch weitere Fragen?<br/>
          Wir freuen uns auf Ihr Anliegen.
        </p>
        <p>

          Telefon: <a href="tel:+493051305057">+49-30-51305057</a><br/>
          Fax: <a href="tel:+493042105843">+49-30-42105843</a><br/>
        E-Mail: <a href="mailto:info@bioverlag.de">info@bioverlag.de</a><br/>
        </p>
        <p>
          Ihr Biopinio-Team
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Zum Dashboard
        </Button>
      </ModalFooter>
    </>
  );
};

export default PageFour;
