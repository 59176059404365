import React from 'react';
import PropTypes from "prop-types";

const DataTableMatrix = ({columns, data}) => {
    console.log(data);

    if (Array.isArray(data)) {
        const labels = data.map(row => row[0]);
        const set = {
            data: data.map(d => [d[1], d[2]])
        };
        data = {labels, datasets: [set]};
    }

    const {datasets, labels} = data;

    return (
        <table className="table table-bordered">
            <thead className="thead-default">
            <tr>
                <th>Fragen</th>
                {datasets.map(column => <th key={column.label}>{column.label}</th>)}
            </tr>
            </thead>
            <tbody>
                {labels.map((label, rowIndex) => (
                    <tr key={`row-${rowIndex}`}>
                        <td key="row-label">{label}</td>
                        {datasets.map((column, columnIndex) =>
                            <td key={`column-answer-${rowIndex}-${columnIndex}`}>{datasets[columnIndex].data[rowIndex][1]} %</td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

DataTableMatrix.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.array),
        PropTypes.shape({
            labels: PropTypes.array.isRequired,
            datasets: PropTypes.array.isRequired
        })
    ]).isRequired
}

export default DataTableMatrix;
