import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

class ModalPresentation extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.sendMail = this.sendMail.bind(this);
  }

  toggle () {
    const {modal} = this.state;
    this.setState({
      modal: !modal
    });
  }

  sendMail (title) {
    const link = 'mailto:info@biopinio.de';
    const subject =
      '?subject=Anfrage Präsentation ' +
      document.querySelector('#userName').value;
    const content =
      '&body=Bitte senden Sie mir die Präsentation für die Umfrage ,' +
      title +
      '\' ' +
      'an folgende E-Mail-Adresse: ' +
      document.querySelector('#userEmail').value;
    window.location.href = link + subject + content;
  }

  render () {
    const {modal} = this.state;
    const {title} = this.props;
    return (
      <div>
        <Button color="primary" onClick={this.toggle}>
          Präsentation anfordern
        </Button>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Möchten Sie hiermit eine kommentierte Präsentation anfordern?
          </ModalHeader>
          <ModalBody>
            Bitte geben Sie hier Ihren Namen und Ihre E-Mail-Adresse ein, an die
            wir anschließend die Präsentation senden.
            <br/>
            <br/>
            <Form action="" method="post" className="form-horizontal">
              <FormGroup row>
                <Col md="12">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-user"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="userName" placeholder="Name"/>
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md="12">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-envelope-o"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="email"
                      id="userEmail"
                      placeholder="E-Mail-Adresse"
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.sendMail(title)}>
              Anfrage erstellen
            </Button>{' '}
            <Button color="secondary" onClick={this.toggle}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ModalPresentation.propTypes = {
  title: PropTypes.string.isRequired
};

export default ModalPresentation;
