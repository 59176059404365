import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ListGroup, ListGroupItem} from 'reactstrap';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import classnames from 'classnames';
import vars from '../../globalVariables';
import parseCommentQuery from '../../functions/parseCommentQuery';

const defaults = {
  maxAge: String(vars.MAX_AGE),
  minAge: String(vars.MIN_AGE),
  socioquestion: 'gender',
  tab: '1'
};

function normalizeQuery (query) {
  const q = Object.assign({}, query);
  if (q.socioquestion && !q.tab) {
    q.tab = '2';
  }
  Object.keys(defaults).forEach(k => {
    if (!q[k]) {
      q[k] = defaults[k];
    }
  });
  const normalized = {};
  Object.keys(q).sort().forEach(k => {
    normalized[k] = q[k];
  });
  return normalized;
}

function equalsCurrentQuery (query) {
  const current =
    normalizeQuery(parseCommentQuery(document.location.search));
  const q = normalizeQuery(query);
  return JSON.stringify(current) === JSON.stringify(q);
}

class CommentPanel extends Component {
  renderCurrentComments (questionComments) {
    const {poll} = this.props;
    const commentItems = questionComments.filter(
      // skip comments without html text
      // FIXME: why would there be comments without html text?
      comment => Boolean(comment.html)
    ).map(comment => {
      // remove back link
      const html = comment.html.replace(
        /(<br>\s*)*<a href=[^>]+>Zurück zur Übersicht<\/a>/
      );

      const query = parseCommentQuery(comment.urlQuery);
      const commentLink = `./${poll._id}?${queryString.stringify(query)}`;
      const activeComment = equalsCurrentQuery(query);

      /* eslint-disable react/no-danger */
      return (
        <ListGroupItem
          key={comment._id}
          className={classnames({commentItem: true, activeComment})}
        >
          <Link to={commentLink}>
            <span className="commentContainer">
              <span dangerouslySetInnerHTML={{__html: html}}/>
            </span>
          </Link>
        </ListGroupItem>
      );
      /* eslint-enable react/no-danger */
    });

    return (<ListGroup>{commentItems}</ListGroup>);
  }

  render () {
    const {poll, questionId} = this.props;
    const {comments} = poll;

    // find comments for current question
    const questionComments = comments.filter(
      c => c.questions.indexOf(questionId) >= 0
    );

    if (questionComments.length === 0) {
      return null;
    }

    return (
      <div className="mb-3">
        <h4>Kommentare</h4>
        {this.renderCurrentComments(questionComments)}
      </div>
    );
  }
}

CommentPanel.propTypes = {
  poll: PropTypes.object.isRequired,
  questionId: PropTypes.string.isRequired
};

export default CommentPanel;
