import React from 'react';
import PropTypes from 'prop-types';
import stopwords from 'stopwords-de';
import Graph from 'react-graph-vis';
import {THEME_COLOR} from '../../globalVariables';
import cleanNetworkPlotString from '../../functions/cleanNetworkPlotString';

function getNetworkPlotData (words, answers, cutoff) {
  cutoff = cutoff || Math.round(words.length / 100 * 2);

  const uniqueWords = words.filter((word, i, ary) => {
    return ary.indexOf(word) === i;
  });

  const wordCounts = {};
  words.forEach(w => {
    wordCounts[w] = 1 + (wordCounts[w] || 0);
  });
  const uniqueWordsCount = uniqueWords.filter(
    w => wordCounts[w] > cutoff
  ).map(w => [w, wordCounts[w]]);

  const results = uniqueWordsCount.map((x, i) => ({
    from: i,
    to: [],
    label: x[0],
    size: x[1]
  }));

  answers.forEach(answer => {
    answer.split(/[.!?]+/).filter(x => x.length > 0).forEach(sentence => {
      const words = cleanNetworkPlotString(sentence).split(/\.|-|,| |;|:/);
      words.forEach(word => {
        if (word.length > 2 && stopwords.indexOf(word.toLowerCase()) === -1) {
          const indexSplitWords = words.map(w => {
            w = results.findIndex(x => x.label === w);
            return w;
          });
          const indexTargetWord = results.findIndex(x => x.label === word);
          if (
            indexTargetWord !== -1 &&
            indexSplitWords.filter(x => x !== -1).length > 0
          ) {
            results[indexTargetWord].to = results[indexTargetWord].to.concat(
              indexSplitWords
            );
          }
        }
      });
    });
  });

  return results;
}

const options = {
  interaction: {
    hover: true
  },
  autoResize: true,
  layout: {
    hierarchical: false
  },
  edges: {
    color: {
      color: '#b0ddae',
      highlight: THEME_COLOR,
      hover: THEME_COLOR,
      opacity: 0.7
    },
    smooth: {
      enabled: true,
      type: 'discrete',
      roundness: 0.5
    },
    selfReferenceSize: 0,
    hoverWidth: 10,
    selectionWidth: 10,
    arrows: {
      from: false,
      to: false
    },
    labelHighlightBold: true
  },
  nodes: {
    widthConstraint: false,
    color: THEME_COLOR,
    shape: 'circle',
    font: {
      color: '#fff',
      face: 'arial'
    }
  }
};

const styleOption = {
  height: '400px'
};

const Network = ({answers, words}) => {
  const graphData = {
    nodes: [],
    edges: []
  };

  const cutoff = Math.round(words.length / 100 * 2);

  const results = getNetworkPlotData(words, answers);
  const countData = [];

  for (let i = 0; i < results.length; i++) {
    countData[results[i].from] = {};
    countData[results[i].from].from = results[i].from;
    countData[results[i].from].to = {};
    countData[results[i].from].label = results[i].label;
    for (let l = 0; l < results[i].to.length; l++) {
      if (
        results[i].to[l] !== -1 &&
        results[i].to[l] !== results[i].from &&
        results[i].size > cutoff
      ) {
        countData[results[i].from].to[results[i].to[l]] =
          1 + (countData[results[i].from].to[results[i].to[l]] || 0);
      }
    }
  }

  for (let i = 0; i < Object.keys(countData).length; i++) {
    const fromData = countData[Object.keys(countData)[i]];
    for (let l = 0; l < Object.keys(fromData.to).length; l++) {
      if (
        Object.keys(
          graphData.edges.filter(
            v =>
              Number(v.from) === Number(Object.keys(fromData.to)[l]) &&
              Number(v.to) === Number(fromData.from)
          )
        ).length === 0
      ) {
        graphData.edges.push({
          from: fromData.from,
          to: Object.keys(fromData.to)[l],
          value: fromData.to[Object.keys(fromData.to)[l]]
        });
      }
    }
    graphData.nodes.push({
      id: results[i].from,
      title: results[i].label,
      label: results[i].label,
      value: results[i].size
    });
  }
  return <Graph graph={graphData} options={options} style={styleOption}/>;
};

Network.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  words: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Network;
