import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';

function handleDownload (url) {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'umfragedaten.csv');
  document.body.append(link);
  link.click();
  link.remove();
}

function quote (text) {
  if (typeof text !== 'string') {
    return text;
  }
  return `"${text.replace('"', '""')}"`;
}

const DataDownloader = ({columns, data, separator}) => {
  const header = columns.map(c => quote(c)).join(',');
  const lines = [];

  if (Array.isArray(data)) {
    data.forEach(row => {
      const cols = [quote(row[0]), row[1], Math.round(row[2] * 10) / 10];
      lines.push(cols.join(separator));
    });
  } else {
    data.datasets.forEach(set => {
      set.data.forEach((d, i) => {
        const cols = [
          quote(set.label),
          quote(data.labels[i]),
          d[0],
          Math.round(d[1] * 10) / 10
        ];
        lines.push(cols.join(separator));
      });
    });
  }

  lines.unshift(header);
  const dataURI = encodeURI(`data:text/csv;charset=utf-8,${lines.join('\n')}`);

  return (
    <Button
      className="download-button"
      color="secondary"
      size="sm"
      onClick={() => handleDownload(dataURI)}
    >
      Tabelle herunterladen
    </Button>
  );
};

DataDownloader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.array),
    PropTypes.shape({
      labels: PropTypes.array.isRequired,
      datasets: PropTypes.array.isRequired
    })
  ]).isRequired,
  separator: PropTypes.string
};

DataDownloader.defaultProps = {
  separator: ','
};

export default DataDownloader;
