/*
 * Determine whether a function can have multiple answers.
 */

import {startpollAgeChildrenQuestionId} from '../globalVariables';

function isMultiQuestion (question) {
  return question.type === 'multiple' ||
    (question.type === 'socioData' &&
      (question.socioDataType === 'ageOfChildren' ||
       // FIXME: remove once we stop matching sociodemographics to question IDs
       question.socioDataType === startpollAgeChildrenQuestionId));
}

export default isMultiQuestion;
