import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {
  Card,
  CardDeck,
  Carousel,
  CarouselControl,
  CarouselItem
} from 'reactstrap';

class PollCardCarousel extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeIndex: 0
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting () {
    this.animating = true;
  }

  onExited () {
    this.animating = false;
  }

  next () {
    const {activeIndex} = this.state;
    const {pollsGroups} = this.props;
    if (this.animating) {
      return;
    }
    const nextIndex = activeIndex === pollsGroups.length - 1
      ? 0
      : activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous () {
    const {activeIndex} = this.state;
    const {pollsGroups} = this.props;
    if (this.animating) {
      return;
    }
    const nextIndex = activeIndex === 0
      ? pollsGroups.length - 1
      : activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex (newIndex) {
    if (this.animating) {
      return;
    }
    this.setState({activeIndex: newIndex});
  }

  renderStatus (poll) {
    const year = new Date(poll.lastAnswerTimeStamp);
    if (year.getTime() > Date.now() - (1000 * 60 * 60 * 24 * 5)) {
      return (
        <div className="state">
          <img src="/img/ongoing.svg" alt=""/>
          Umfrage noch nicht abgeschlossen
        </div>
      );
    }
    return (
      <div className="state">
        <img src="/img/green-check.svg" alt=""/>
        Umfrage abgeschlossen
      </div>
    );
  }

  render () {
    const {activeIndex} = this.state;
    const {pollsGroups} = this.props;
    if (!pollsGroups) {
      return null;
    }

    const polls = pollsGroups.map((threePolls, index) => {
      const pollsGroup = threePolls.map(poll => {
        return (
          <Card key={poll.pollid}>
            <div className="card-block">
              <h4>{poll.title}</h4>
              {this.renderStatus(poll)}
              <p>
                <span className="first">Start</span>
                <span className="second">
                  {moment(poll.firstAnswerTimeStamp).format('DD.MM.YYYY')}
                </span>
                <br/>
                <span className="first">Ende</span>
                <span className="second">
                  {moment(poll.lastAnswerTimeStamp).format('DD.MM.YYYY')}
                </span>
                <br/>
                <span className="first">Antworten</span>
                <span className="second">{poll.submissionCount}</span>
              </p>
              <Link to={`/biopinio/${poll.pollid}`} className="pollLink">
                <span>Zur Umfrage</span>
                <img src="/img/caret-right.svg" alt=""/>
              </Link>
            </div>
          </Card>
        );
      });
      return (
        // eslint-disable-next-line react/no-array-index-key
        <CarouselItem key={`group_${index}`}>
          <CardDeck>
            {pollsGroup}
          </CardDeck>
        </CarouselItem>
      );
    });

    return (
      <Carousel
        interval="100000000"
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        className="pollCarousel"
      >
        {polls}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={this.previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={this.next}
        />
      </Carousel>
    );
  }
}

export default PollCardCarousel;
