import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem
} from 'reactstrap';
import Loading from '../Loading';
import usePanelBookCharts from '../../hooks/usePanelBookCharts';

// FIXME: the questions to be displayed should be configured in the database
// mapping start poll question IDs to chart types
const selectedQuestions = {
  '61ce13b0077e7167af1581de': 'doughnut',
  '61ce14832ee82f6ae69d654f': 'doughnut',
  '61ce155e2ee82f6ae69d6551': 'doughnut',
  '61d422da9b06d3677e6e42f6': 'doughnut'
};

// ['#1dbc7a', '#ff6c6c', '#ffbf3c', '#00a9d4', '#dcdcdc']

let animating = false;

const PanelBookCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const charts = usePanelBookCharts(selectedQuestions);

  const slides = charts ? charts.map(chart => {
    return (
      <CarouselItem
        key={chart.key}
        onExiting={() => {
          animating = true;
        }}
        onExited={() => {
          animating = false;
        }}
      >
        <div>
          <p>{chart.title}</p>
          <div style={{margin: '0 80px 20px'}}>
            {chart.chart}
          </div>
        </div>
      </CarouselItem>
    );
  }) : [];

  function next () {
    if (animating) {
      return;
    }
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  function previous () {
    if (animating) {
      return;
    }
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  function goToIndex (newIndex) {
    if (animating) {
      return;
    }
    setActiveIndex(newIndex);
  }

  return (
    <div>
      <h4 className="overview">biopinio Panelbuch</h4>
      <Card>
        <CardBody>
          {charts ? (
            <Carousel
              interval="30000"
              activeIndex={activeIndex}
              next={next}
              previous={previous}
            >
              <CarouselIndicators
                items={charts}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
          ) : <Loading/>}
        </CardBody>
      </Card>
    </div>
  );
};

export default PanelBookCarousel;
