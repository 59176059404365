import socioProps from '../socioProps';
import isMultiQuestion from './isMultiQuestion';
import translateAnswer from './translateAnswer';
import getSociodemographicQuestionAnsweroptions
  from './getSociodemographicQuestionAnsweroptions';

/*
 * Compile data set for a grouped bar chart.
 *
 * @param {object} poll Poll definition
 * @param {array} submissions The submitted answers
 * @param {object} question Question specification
 * @param {string} groupQuestionId Either a sociodemographic type or the ID of
 *  the question the plot data is to be grouped by.
 */

function compileDataset (submissions, question, groupQuestion) {
  // we cannot handle freetext questions
  if (question.type === 'freetext') {
    return null;
  }

  const questionId = question._id || question.socioDataType;
  const groupQuestionId = groupQuestion._id || groupQuestion.socioDataType;

  // determine answer options of grouping question
  let groupAnswerOptions = [];
  if (groupQuestion.type === 'socioData') {
    groupAnswerOptions = socioProps[groupQuestion.socioDataType].values
      .map(v => translateAnswer(v));
  } else if (['single', 'boolean'].indexOf(groupQuestion.type) > -1) {
    groupAnswerOptions = [
      ...new Set(submissions.map(s => s[groupQuestionId]))
    ];
  } else {
    console.warn(
      'Unsupported grouping question type: %s',
      groupQuestion.type
    );
    return null;
  }

  const groupObservations = submissions.reduce((p, s) => {
    const answer = s[groupQuestionId];
    p[answer] = 1 + (p[answer] || 0);
    return p;
  }, {});

  const isMulti = isMultiQuestion(question);

  const result = submissions.map(s => {
    const answer = s[questionId];
    const groupingAnswer = s[groupQuestionId];
    if (typeof answer === 'undefined') {
      return [];
    }
    const answers = isMulti ? answer.split(';') : [answer];
    return answers.map(a => ({
      [questionId]: a,
      [groupQuestionId]: groupingAnswer
    }));
  }).flat();

  const uniqueQuestionAnswers = [
    ...new Set(result.map(s => s[questionId]))
  ].filter(n => {
    return n !== undefined;
  });

  // FIXME: if a sociodemographic answer isn't hadn't been include in the
  // subissions (e.g. income in poll 972db361de4dd826) we don't get results here
  // we may have to refer to startpoll questions. Alternatively we could refuse
  // to render a chart when data is unavailable.
  const submissionsByGroupAnswer = result.reduce((grouped, sub) => {
    const groupAnswer = sub[groupQuestionId];
    grouped[groupAnswer] = grouped[groupAnswer] || [];
    grouped[groupAnswer].push(sub);
    return grouped;
  }, {});

  const givenGroupAnswers = groupAnswerOptions.filter(
    o => Boolean(submissionsByGroupAnswer[o])
  );

  const countDataGroup = [];
  const countDataOverall = {};

  givenGroupAnswers.forEach(groupAnswer => {
    const answers = [];
    const subs = submissionsByGroupAnswer[groupAnswer];
    subs.forEach(s => {
      answers.push(s[questionId]);
    });
    const countData = {};
    answers.forEach(a => {
      countData[a] = 1 + (countData[a] || 0);
      countDataOverall[a] = 1 + (countDataOverall[a] || 0);
    });

    const group = {};
    answers.forEach(a => {
      group[a] = [
        countData[a],
        countData[a] / groupObservations[groupAnswer] * 100
      ];
    });
    countDataGroup.push(group);
  });

  let questionAnswerOptions = [];

  if (question.type === 'socioData') {
    questionAnswerOptions = getSociodemographicQuestionAnsweroptions(
      question.socioDataType
    ).filter(
      x => uniqueQuestionAnswers.indexOf(x) !== -1
    );
  } else {
    questionAnswerOptions = question.isOrderByAnswerOccurence
      ? Object.keys(countDataOverall).sort((a, b) => {
        return countDataOverall[b] - countDataOverall[a];
      }).map(
        x => translateAnswer(x)
      ) : question.answeroptions.map(
        x => translateAnswer(x.text)
      ).filter(
        x => uniqueQuestionAnswers.map(
          x => translateAnswer(x)
        ).indexOf(x) !== -1
      );
  }

  const plotData = {
    labels: questionAnswerOptions,
    datasets: []
  };

  countDataGroup.forEach((g, i) => {
    const answers = [];
    questionAnswerOptions.forEach(o => {
      answers.push(g[o] || [0, 0]);
    });
    plotData.datasets.push({
      data: answers,
      label: givenGroupAnswers[i]
    });
  });

  return plotData;
}

export default compileDataset;
