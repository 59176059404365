import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clipAnsweroptionText from '../../functions/clipAnsweroptionText';
import {THEME_COLOR} from '../../globalVariables';
import Toggle from '../Toggle';
import BarChart from '../Charts/BarChart';
import Freetext from '../Charts/Freetext';

const GraphicsFirstTab = ({dataSet}) => {
  const [showValues, setShowValues] = useState(false);

  const {countData, answerOptions, question} = dataSet;

  if (question.type === 'freetext') {
    return <Freetext answers={Object.keys(dataSet.countData)}/>;
  }

  const labels = answerOptions.map(
    o => clipAnsweroptionText(o.toString()).split('##')
  );

  const percentages = answerOptions.map(o => countData[o] ? countData[o][1] : null);

  const plotData = {
    labels,
    datasets: [{
      backgroundColor: THEME_COLOR,
      data: percentages
    }]
  };

  return (
    <div>
      <Toggle checked={showValues} onChange={() => setShowValues(!showValues)}>
        Werte anzeigen
      </Toggle>
      <BarChart {...{dataSet, plotData, showValues}}/>
    </div>
  );
};

GraphicsFirstTab.propTypes = {
  dataSet: PropTypes.shape({
    countData: PropTypes.object.isRequired,
    cutoffInclusion: PropTypes.number.isRequired,
    numSubmissions: PropTypes.number.isRequired,
    question: PropTypes.object.isRequired,
    answerOptions: PropTypes.array
  }).isRequired
};

export default GraphicsFirstTab;
