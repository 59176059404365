import React from 'react';
import PropTypes from 'prop-types';
import ReactWordcloud from 'react-wordcloud';
import {THEME_COLOR} from '../../globalVariables';

const Wordcloud = ({words}) => {
  const cutoff = Math.round(words.length / 100 * 2);

  const wordCounts = {};
  words.forEach(w => {
    wordCounts[w] = 1 + (wordCounts[w] || 0);
  });

  const data = Object.keys(wordCounts).filter(
    w => wordCounts[w] > cutoff
  ).map(text => ({text, value: wordCounts[text]}));

  return (
    <div style={{clear: 'both', height: 400, width: '100%', maxWidth: 600}}>
      <ReactWordcloud
        words={data}
        options={{
          colors: [THEME_COLOR],
          fontFamily: 'Arial',
          fontSizes: [14, 50],
          rotations: 1,
          rotationAngles: [0, 90]
        }}
      />
    </div>
  );
};

Wordcloud.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Wordcloud;
