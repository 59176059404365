// translate text and clip it when necessary

const translations = {
  'not answered': 'Keine Antwort',
  NA: 'Keine Antwort',
  yes: 'Ja',
  no: 'Nein',
  male: 'Männlich',
  female: 'Weiblich',
  diverse: 'Divers',
  daily: 'Täglich',
  weekly: 'Wöchentlich',
  monthly: 'Monatlich',
  never: 'Nie',
  'less than monthly': 'Seltener als monatlich',
  'less than 5000': 'Weniger als 5.000',
  '5000 - 19999': '5.000 bis 19.999',
  '20000 - 99999': '20.000 bis 99.999',
  '100000 - 1000000': '100.000 bis 1 Mio.',
  'more than 1000000': 'Mehr als 1 Mio.',
  'less than €1000': 'Unter €1.000',
  '€1000 - €1500': '€1.000 bis €1.500',
  '€1500 - €2000': '€1.500 bis €2.000',
  '€2000 - €2500': '€2.000 bis €2.500',
  '€2500 - €3000': '€2.500 bis €3.000',
  '€3000 - €4000': '€3.000 bis €4.000',
  '€4000 - €5500': '€4.000 bis €5.500',
  '€5500 - €10000': '€5.500 bis €10.000',
  'more than €10000': 'Mehr als €10.000',
  omnivore: 'Alles-Esser',
  vegetarian: 'Vegetarier',
  vegan: 'Veganer',
  flexitarian: 'Flexitarier',
  pescetarian: 'Pescetarier',
  fruitarian: 'Frutarier',
  'raw foodist': 'Rohkostler',
  paleo: 'Paleo',
  other: 'Sonstiges',
  nodegree: 'Kein Schulabschluss',
  lower: 'Haupt- oder Realschulabschluss',
  upper: 'Abitur oder Fachabitur',
  vocational: 'Berufsausbildung',
  bachelor: 'Bachelor (FH oder Uni)',
  master: 'Master oder gleichwertig (FH oder Uni)',
  PhD: 'Promotion',
  'under 1': 'unter 1 Jahr',
  '10 or more': '10 oder mehr',
  '18 - 29': '18 - 29 Jahre',
  '30 - 39': '30 - 39 Jahre',
  '40 - 49': '40 - 49 Jahre',
  '50+': '50 Jahre und älter',
  'Ravioli Tomate-Mozarella': 'Ravioli Tomate-Mozarella / Tortelloni',
  'Ravioli Tomate-Mozzarella': 'Ravioli Tomate-Mozarella / Tortelloni',
  Tortelloni: 'Ravioli Tomate-Mozarella / Tortelloni',
  'Eduscho/Tschibo': 'Eduscho/Tchibo'
};

function translateAnswer (text) {
  if (typeof text === 'undefined') {
    return 'Keine Antwort';
  }
  return translations[text] || text;
}

export default translateAnswer;
