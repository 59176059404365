import React from 'react';
import PropTypes from 'prop-types';
import {Label, Input} from 'reactstrap';

const Toggle = ({checked, onChange, children}) => {
  return (
    <div className="toggle">
      <span>{children}</span>
      <Label className="switch switch-default">
        <Input
          type="checkbox"
          className="switch-input"
          checked={checked}
          onChange={onChange}
        />
        <span className="switch-label"/>
        <span className="switch-handle"/>
      </Label>
    </div>
  );
};

Toggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  children: PropTypes.string
};

Toggle.defaultProps = {
  checked: false,
  children: null
};

export default Toggle;
