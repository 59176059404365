import React, {useEffect, useState} from 'react';
import {Doughnut} from 'react-chartjs-2';
import api from '../api';
import socioProps from '../socioProps';
import {THEME_COLOR} from '../globalVariables';
import BarChart from '../components/Charts/BarChart';
import checkBifurcation from '../functions/checkBifurcation';
import massageData from '../functions/massageData';
import {interpolateColors} from '../functions/colorHelpers';

const doughnutOpts = {
  tooltips: {
    mode: 'label',
    callbacks: {
      label (tooltipItem, data) {
        const {index, datasetIndex} = tooltipItem;
        const label = data.labels[index];
        const val = Math.round(data.datasets[datasetIndex].data[index]);
        return `${label} ${val}%`;
      }
    }
  },
  legend: {
    position: 'right'
  }
};

// truncate and cleanup question texts
const questionText2Title = text => {
  if (socioProps[text]) {
    return socioProps[text].questionText;
  }
  text = text.replace(/<br>.*$/, '').replace(/\([^)]+\)$/, '');
  return text;
};

const truncate = (text, maxLen = 35) => {
  if (text.length <= maxLen) {
    return text;
  }
  text = text.replace(/ \([^)]+\)$/, '');
  text += ' ';
  return text.substring(0, text.lastIndexOf(' ', maxLen)) + '...';
};

function usePanelBookCharts (questionSpec) {
  const [pollData, setPollData] = useState(null);

  useEffect(() => {
    async function fetchPollData () {
      try {
        const response = await api.get('/customer/panelbook');
        setPollData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchPollData();
  }, []);

  if (!pollData) {
    return null;
  }

  const questions = pollData.poll.questions.filter(
    q => Boolean(questionSpec[q._id])
  ).map(q => {
    q.chartType = questionSpec[q._id];
    return q;
  });

  const charts = questions.map(question => {
    const title = questionText2Title(question.text);
    const submissions = checkBifurcation(question, pollData);
    const dataSet = massageData(submissions, question);
    const {countData, answerOptions} = dataSet;
    const percentages = answerOptions.map(o => countData[o][1]);

    // truncate labels for bar charts
    // FIXME: add the full text to tooltips
    const labels = question.chartType === 'bar' ? answerOptions.map(o => {
      return truncate(o.toString(), 30, '...');
    }) : answerOptions;

    const plotData = {
      labels,
      datasets: [{
        backgroundColor: THEME_COLOR,
        data: percentages
      }]
    };

    if (question.chartType === 'doughnut') {
      plotData.datasets[0].backgroundColor = interpolateColors(
        'rgb(137, 217, 134)',
        'rgb(25, 73, 23)',
        plotData.datasets[0].data.length
      ).map(c => `rgb(${c})`).reverse();
    }

    const chart = question.chartType === 'bar' ? (
      <BarChart {...{dataSet, plotData}}/>
    ) : <Doughnut data={plotData} options={doughnutOpts}/>;

    const key = question._id;

    return {key, title, chart, question};
  });

  return charts;
}

export default usePanelBookCharts;
