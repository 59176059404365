import React from 'react';

function Loading () {
  return (
    <p>
      <i className="fa fa-circle-o-notch fa-lg fa-spin mr-2"/>
      <span>Daten werden geladen...</span>
    </p>
  );
}

export default Loading;
