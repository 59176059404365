import vars from '../globalVariables';
import socioProps from '../socioProps';

const questions2keys = {
  citySize: [
    vars.startpollCitysizeQuestionId,
    vars.oldStartpollCitysizeQuestionId,
    vars.newStartpollCitysizeQuestionId
  ],
  gender: [
    vars.startpollGenderQuestionId,
    vars.pastapollGenderQuestionId,
    vars.fruitSpreadBranchGenderQuestionId,
    vars.fruitSpreadPanelGenderQuestionId,
    vars.startpollPanelGenderQuestionId,
    vars.nextOrganicPanelGenderQuestionId,
    vars.oldStartpollGenderQuestionId,
    vars.newStartpollGenderQuestionId
  ],
  organicConsumptionFrequency: [
    vars.startpollOrganicConsumptionFrequencyQuestionId,
    vars.pastapollOrganicConsumptionFrequencyQuestionId,
    vars.fruitSpreadBranchOrganicConsumptionFrequencyQuestionId,
    vars.fruitSpreadPanelOrganicConsumptionFrequencyQuestionId,
    vars.startpollPanelOrganicConsumptionFrequencyQuestionId,
    vars.oldStartpollOrganicConsumptionFrequencyQuestionId,
    vars.newStartpollOrganicConsumptionFrequencyQuestionId
  ],
  income: [
    'Was ist Dein monatliches Haushaltsnettoeinkommen?',
    vars.startpollIncomeQuestionId,
    vars.oldStartpollIncomeQuestionId,
    vars.newStartpollIncomeQuestionId
  ],
  levelOfEducation: [
    'Was ist Dein höchster erlangter Ausbildungsabschluss?',
    vars.startpollEducationQuestionId,
    vars.oldStartpollEducationQuestionId,
    vars.newStartpollEducationQuestionId
  ],
  diet: [
    'Welche der folgenden Bezeichnungen passt am besten zu Deinem ' +
    'Essverhalten?',
    vars.startpollDietQuestionId,
    vars.oldStartpollDietQuestionId,
    vars.newStartpollDietQuestionId
  ],
  hasChildren: [
    vars.startpollHavingChildrenQuestionId,
    vars.newStartpollHavingChildrenQuestionId
  ],
  ageOfChildren: [
    vars.startpollAgeChildrenQuestionId,
    vars.newStartpollAgeChildrenQuestionId
  ],
  numberOfHouseholdMembers: [
    vars.startpollNumberPplHouseholdQuestionId,
    vars.newStartpollNumberPplHouseholdQuestionId
  ],
  homeRegion: [vars.newStartpollHomeRegionQuestionId]
};

const keys2question = {};
Object.keys(questions2keys).forEach(key => {
  questions2keys[key].forEach(q => {
    keys2question[q] = key;
  });
});

const questionTexts = {
  X55311ea47c918d16406ec60b: 'Pastasorte',
  X7fed33c6c98d5898: 'Fruchtaufstrich',
  X55bd2e2537a9c3f83d19e5cf: 'Häufigkeit Limonade'
};
// add question texts from socioProps
Object.keys(socioProps).forEach(prop => {
  questionTexts[prop] = socioProps[prop].questionText;
});

/*
 * This functions takes a question ID or a question text (possibly containing
 * HTML tags) and tries to find a canonical, abbreviated text for this question.
 * If no text is found the input is returned with HTML tags stripped.
 */
function getQuestionText (text) {
  if (!text) {
    return 'NA';
  }
  // strip tags
  // FIXME: use library function
  text = text.replace(/<\/?[^>]+(>|$)/g, '');
  // is it a sociodemographic question?
  const key = keys2question[text] || text;
  // does it have a canonical text?
  return questionTexts[key] || text;
}

export default getQuestionText;
