import {BIRTH_YEAR_QUESTION_ID, newStartpollDate} from '../globalVariables';

function setAgeGroup (submissions) {
  const startDate = new Date(Math.min(...submissions.map(s => s.creationTime)));
  // year to base age calculation on
  const baseYear = startDate.getFullYear();

  const birthYearQuestion = startDate > newStartpollDate
    ? 'birthYear'
    : BIRTH_YEAR_QUESTION_ID;

  submissions.forEach(s => {
    const age = baseYear - s[birthYearQuestion];
    if (age > 17 && age < 30) {
      s.ageGroup = '18 - 29 Jahre';
    } else if (age >= 30 && age < 40) {
      s.ageGroup = '30 - 39 Jahre';
    } else if (age >= 40 && age < 50) {
      s.ageGroup = '40 - 49 Jahre';
    } else if (age > 50) {
      s.ageGroup = '50 Jahre und älter';
    } else {
      s.ageGroup = undefined;
    }
  });
}

export default setAgeGroup;
