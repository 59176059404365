import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Bar, HorizontalBar} from 'react-chartjs-2';
import {interpolateColors} from '../../functions/colorHelpers';
import clipAnsweroptionText from '../../functions/clipAnsweroptionText';
import Toggle from '../Toggle';

const axisDesignCategories = [
  {
    ticks: {
      stepSize: 1,
      autoSkip: false
    }
  }
];

const axisDesignValues = [
  {
    ticks: {
      beginAtZero: true,
      callback (value) {
        return value + '%';
      }
    }
  }
];

const chartOptsDefaults = {
  scaleShowValues: true,
  scales: {
    yAxes: [],
    xAxes: []
  },
  legend: {
    display: true,
    position: 'top'
  },
  title: {
    display: true,
    position: 'top',
    fontStyle: 'normal',
    text: []
  },
  tooltips: {
    enabled: true,
    mode: 'label',
    callbacks: {
      label (tooltipItem, data) {
        return (
          data.datasets[tooltipItem.datasetIndex].label +
          ' ' +
          Math.round(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          ) +
          '%'
        );
      }
    }
  }
};

class GroupedBar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      valuesShown: false
    };
    this.handleValuesToggle = this.handleValuesToggle.bind(this);
  }

  handleValuesToggle () {
    const {valuesShown} = this.state;
    this.setState({
      valuesShown: !valuesShown
    });
  }

  render () {
    const {valuesShown} = this.state;
    const {data, question} = this.props;

    if (data.datasets.length === 0) {
      return (
        <div className="no-wordloud">
          <p>Keine Daten zur Analyse vorhanden.</p>
        </div>
      );
    }

    const chartOpts = Object.assign({}, chartOptsDefaults);

    // flatten data points to percentages removing total numbers
    const chartData = {
      labels: data.labels,
      datasets: data.datasets.map(set => {
        return {
          label: set.label,
          data: set.data.map(x => x[1])
        };
      })
    };

    if (valuesShown) {
      chartOpts.tooltips = {
        enabled: false
      };
      chartOpts.animation = {
        duration: 1,
        onComplete () {
          const chartInstance = this.chart;
          const {ctx} = chartInstance;
          ctx.textAlign = 'center';
          ctx.fillStyle = 'rgba(0, 0, 0, 1)';
          ctx.textBaseline = 'center';
          ctx.font = '10pt Arial';

          this.data.datasets.forEach((dataset, i) => {
            const meta = chartInstance.controller.getDatasetMeta(i);
            if (meta.type === 'horizontalBar') {
              meta.data.forEach((bar, index) => {
                const data = Math.round(dataset.data[index]) + ' %';
                ctx.fillText(data, bar._model.x + 20, bar._model.y);
              });
            } else {
              meta.data.forEach((bar, index) => {
                const data = Math.round(dataset.data[index]) + ' %';
                ctx.fillText(data, bar._model.x, bar._model.y - 10);
              });
            }
          });
        }
      };
    } else {
      chartOpts.animation = {
        duration: 1000,
        onComplete () {}
      };
    }

    const colors = interpolateColors(
      'rgb(137, 217, 134)',
      'rgb(25, 73, 23)',
      chartData.datasets.length
    );

    for (let i = 0; i < chartData.datasets.length; i++) {
      chartData.datasets[i].backgroundColor = 'rgb(' + colors[i] + ')';
    }

    // use horizontal bar chart in case of more than 12 answer options or if
    // length of any answer option string is greater than 30, except for socio
    // get maximal length of answer option labels
    const maxStringLength = Math.max(...chartData.labels.map(x => x.length));

    // clip text
    chartData.labels = chartData.labels.map(element => {
      return clipAnsweroptionText(element.toString()).split('##');
    });

    chartOpts.layout = {padding: {right: 0, top: 0}};

    let chart;

    if (
      (chartData.labels.length > 12 || maxStringLength > 30) &&
      question.type !== 'socioData'
    ) {
      chartOpts.scales.xAxes = axisDesignValues;
      chartOpts.scales.yAxes = axisDesignCategories;
      chart = (
        <HorizontalBar
          data={chartData}
          options={chartOpts}
          height={150}
        />
      );
    } else {
      chartOpts.scales.xAxes = axisDesignCategories;
      chartOpts.scales.yAxes = axisDesignValues;
      chart = <Bar data={chartData} options={chartOpts} height={150}/>;
    }

    return (
      <div>
        <Toggle checked={valuesShown} onChange={this.handleValuesToggle}>
          Werte anzeigen
        </Toggle>
        <div>{chart}</div>
      </div>
    );
  }
}

GroupedBar.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array.isRequired,
    datasets: PropTypes.array.isRequired
  }).isRequired,
  question: PropTypes.object.isRequired
};

export default GroupedBar;
