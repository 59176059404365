import React, {Component} from 'react';

class RadioButtonGroup extends Component {
  renderRadioButtons () {
    const {count, firstLabel, lastLabel} = this.props;
    const radioButtons = [];
    for (let i = 1; i <= count; i++) {
      radioButtons.push(
        <div key={`rb-${i}`}>
          <span className="circle"/>
          <span className="label">
            {i}
            {i === 1 ? ' ' + firstLabel : ''}
            {i === count ? ' ' + lastLabel : ''}
          </span>
        </div>
      );
    }
    return radioButtons;
  }

  render () {
    return (
      <div>{this.renderRadioButtons()}</div>
    );
  }
}

export default RadioButtonGroup;
