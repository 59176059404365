import isMultiQuestion from './isMultiQuestion';
import translateAnswer from './translateAnswer';
import getSociodemographicQuestionAnsweroptions
  from './getSociodemographicQuestionAnsweroptions';

/*
 * last preparations before standard plot
 */
function massageData (submissions, question) {
  const questionId = question._id || question.socioDataType;

  const numSubmissions = submissions.length;

  const answers = [];
  submissions.forEach(s => {
    const answer = s[questionId];
    if (isMultiQuestion(question)) {
      if (typeof answer !== 'undefined') {
        answer.split(';').forEach(a => answers.push(a));
      }
    } else {
      answers.push(answer);
    }
  });

  // calculate inclusion cutoff based on string without 'keine antwort'
  const cutoffInclusion = Math.round(answers.length / 100 * 2);

  const countAnswers = {};
  answers.forEach(a => {
    countAnswers[a] = 1 + (countAnswers[a] || 0);
  });

  const uniqueAnswers = Object.keys(countAnswers);

  // combine absolute numbers and percentages
  const countData = {};
  uniqueAnswers.forEach(a => {
    const n = countAnswers[a];
    countData[a] = [n, n / numSubmissions * 100];
  });

  const data = {countData, cutoffInclusion, numSubmissions, question};

  if (question.type === 'socioData') {
    data.answerOptions = getSociodemographicQuestionAnsweroptions(
      question.socioDataType
    ).filter(a => uniqueAnswers.indexOf(a) !== -1);
    console.log(data);
  } else if (question.type !== 'freetext') {
    // NB: we need to translate answers here to match boolean question types
    data.answerOptions = question.isOrderByAnswerOccurence
      ? uniqueAnswers.sort((a, b) => {
        return countData[b][0] - countData[a][0];
      }).map(a => translateAnswer(a))
      : question.answeroptions.map(o => translateAnswer(o.text)).filter(
        a => uniqueAnswers.indexOf(a) !== -1
      );
  }

  return data;
}

export default massageData;
