import React from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import vars from '../../globalVariables';

const Slider = require('rc-slider');

const {createSliderWithTooltip} = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const AgeSlider = ({ageRange, onAgeSelect}) => {
  return (
    <div className="range-container">
      <span>
        Filter nach Alter der Antwortenden
      </span>
      <Range
        className="age-slider"
        active="false"
        min={vars.MIN_AGE}
        max={vars.MAX_AGE}
        value={ageRange}
        marks={{
          20: '20',
          30: '30',
          40: '40',
          50: '50',
          60: '60',
          70: '70',
          80: '80',
          90: '90'
        }}
        step={1}
        allowCross={false}
        trackStyle={[{backgroundColor: '#3EAB37'}]}
        activeDotStyle={{borderColor: '#3EAB37'}}
        handleStyle={[{borderColor: '#9FB7C0'}]}
        onChange={onAgeSelect}
      />
    </div>
  );
};

AgeSlider.propTypes = {
  ageRange: PropTypes.array.isRequired,
  onAgeSelect: PropTypes.func.isRequired
};

export default AgeSlider;
