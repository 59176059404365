/*
 * Filter pseudo-questions ('itermission', 'handOff' etc.) from poll data and
 * add sociodemographic questions.
 */

import socioProps from '../socioProps';

const exclude = [
  'intermission',
  'address',
  'handOff'
];

const includeSocioProps = [
  'gender',
  'ageGroup',
  'citySize',
  'organicConsumptionFrequency',
  'income',
  'levelOfEducation',
  'diet',
  'hasChildren',
  'ageOfChildren',
  'numberOfHouseholdMembers'
];

function filterQuestions (questions) {
  const pollSocioQuestions = questions.filter(q => q.type === 'socioData').map(
    q => q.socioDataType
  );
  const filtered = questions.filter(q => exclude.indexOf(q.type) === -1);
  includeSocioProps.forEach(p => {
    if (pollSocioQuestions.indexOf(p) === -1) {
      filtered.push({
        _id: p,
        text: socioProps[p].questionText
      });
    }
  });
  return filtered;
}

export default filterQuestions;
