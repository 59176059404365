import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {apiUrl} from '../config';

const FIVE_DAYS = 1000 * 60 * 60 * 24 * 5;

class Polls extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      polls: [],
      filteredPolls: [],
      sortBy: 'firstAnswerTimeStamp',
      sortDir: 'DESC',
      inputVal: [],
      tableVersion: 'long',
      buttonText: 'Alle Umfragen'
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.sortRowsBy = this.sortRowsBy.bind(this);
    this.handleTableSize = this.handleTableSize.bind(this);
  }

  componentDidMount () {
    this.loadPolls();
  }

  loadPolls () {
    const url = `${apiUrl}/customer/polls`;
    const opts = {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
      credentials: 'include'
    };
    fetch(url, opts)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        const {polls} = data;

        const activeFilteredPolls = polls.filter(x => {
          const year = new Date(x.lastAnswerTimeStamp);
          if (year.getTime() > Date.now() - FIVE_DAYS) {
            return x;
          }
          return false;
        });

        this.setState({
          polls,
          filteredPolls: polls,
          activeFilteredPolls,
          allFilteredPolls: polls
        });
      })
      .catch(error => {
        console.log('cannot load data', error);
      });
  }

  handleFilterChange (e) {
    const {polls, tableVersion} = this.state;

    const filterBy = e.target.value.toLowerCase();

    const activeFilteredPolls = polls.filter(x => {
      const year = new Date(x.lastAnswerTimeStamp);
      if (year.getTime() > Date.now() - FIVE_DAYS &&
        x.title.toLowerCase().indexOf(filterBy) !== -1
      ) {
        return x;
      }
      return false;
    });

    const allFilteredPolls = polls.filter(x => {
      if (x.title.toLowerCase().indexOf(filterBy) !== -1) {
        return x;
      }
      return false;
    });

    const filteredIndexes = tableVersion === 'long'
      ? allFilteredPolls
      : activeFilteredPolls;

    this.setState({
      filteredPolls: filteredIndexes,
      allFilteredPolls,
      activeFilteredPolls,
      inputVal: e.target.value
    });
  }

  sortRowsBy (cellDataKey) {
    let {sortDir} = this.state;
    const {filteredPolls, sortBy} = this.state;
    const sortByNew = cellDataKey;
    if (sortByNew === sortBy) {
      sortDir = sortDir === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortDir = 'DESC';
    }
    const rows = filteredPolls.slice();
    rows.sort((a, b) => {
      let sortVal = 0;
      if (a[sortByNew] > b[sortByNew]) {
        sortVal = 1;
      }
      if (a[sortByNew] < b[sortByNew]) {
        sortVal = -1;
      }

      if (sortDir === 'DESC') {
        sortVal *= -1;
      }
      return sortVal;
    });

    this.setState(prevState => ({
      sortBy: sortByNew || prevState,
      sortDir,
      filteredPolls: rows
    }));
  }

  handleTableSize (value) {
    this.setState(prevState => ({
      tableVersion: value === 'long' ? 'short' : 'long',
      buttonText:
        value === 'long'
          ? 'Alle Umfragen'
          : 'Aktive Umfragen',
      filteredPolls:
        value === 'long'
          ? prevState.allFilteredPolls
          : prevState.activeFilteredPolls,
      sortDir: null
    }));
  }

  render () {
    const {
      filteredPolls,
      sortDir,
      sortBy,
      tableVersion,
      buttonText,
      inputVal
    } = this.state;

    filteredPolls.map(x => {
      if (typeof x.firstAnswerTimeStamp !== 'undefined') {
        x.firstAnswerTimeStampFormat =
          moment(x.firstAnswerTimeStamp).format('DD. MMM YYYY');
      }
      if (typeof x.lastAnswerTimeStamp !== 'undefined') {
        x.lastAnswerTimeStampFormat =
          moment(x.lastAnswerTimeStamp).format('DD. MMM YYYY');
      }
      return x;
    });

    const rows = filteredPolls.map(poll => {
      return <TableRow key={poll.pollid} data={poll}/>;
    });

    return (
      <div className="page poll-list animated fadeIn">
        <h1>Alle Umfragen über die biopinio App</h1>
        <div className="poll-list-controls">
          <input
            placeholder="Nach Umfragetitel filtern"
            value={inputVal}
            className="form-control form-control-md"
            onChange={this.handleFilterChange}
          />
          <span className="switch">
            <span>Anzeige:</span>
            <Button
              color="secondary"
              size="sm"
              onClick={() => {
                this.handleTableSize(tableVersion);
              }}
            >
              {buttonText}
            </Button>
          </span>
        </div>
        <div className="polls-table-wrapper">
          <table className="table table-bordered">
            <TableHead
              sortRowsBy={this.sortRowsBy}
              sortDir={sortDir}
              sortBy={sortBy}
            />
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

class TableHead extends React.Component {
  render () {
    const {sortDir, sortBy, sortRowsBy} = this.props;
    console.log();
    let sortDirArrow = '';
    if (sortDir !== null) {
      sortDirArrow = sortDir === 'DESC' ? ' ↓' : ' ↑';
    }
    return (
      <thead className="thead-default">
        <tr>
          <th onClick={sortRowsBy.bind(this, 'title')}>
            {' '}
            {'Umfragetitel' + (sortBy === 'title' ? sortDirArrow : '')}{' '}
          </th>
          <th onClick={sortRowsBy.bind(this, 'customerName')}>
            {' '}
            {'Kunde' + (sortBy === 'customerName' ? sortDirArrow : '')}{' '}
          </th>
          <th onClick={sortRowsBy.bind(this, 'submissionCount')}>
            {' '}
            {'Anzahl der Antworten' +
              (sortBy === 'submissionCount' ? sortDirArrow : '')}{' '}
          </th>
          <th onClick={sortRowsBy.bind(this, 'firstAnswerTimeStamp')}>
            {' '}
            {'erste Antwort' +
              (sortBy === 'firstAnswerTimeStamp' ? sortDirArrow : '')}{' '}
          </th>
          <th onClick={sortRowsBy.bind(this, 'lastAnswerTimeStamp')}>
            {' '}
            {'letzte Antwort' +
              (sortBy === 'lastAnswerTimeStamp' ? sortDirArrow : '')}{' '}
          </th>
          <th>Aktion</th>
        </tr>
      </thead>
    );
  }
}

TableHead.propTypes = {
  sortDir: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortRowsBy: PropTypes.func.isRequired
};

class TableRow extends React.Component {
  render () {
    const {data} = this.props;
    return (
      <tr>
        <td>{data.title}</td>
        <td>{data.customerName}</td>
        <td>{data.submissionCount}</td>
        <td>{data.firstAnswerTimeStampFormat}</td>
        <td>{data.lastAnswerTimeStampFormat}</td>
        <td>
          <Link className="pollLink" to={`/biopinio/${data.pollid}`}>
            <span>Zur Umfrage</span>
            <img src="/img/caret-right.svg" alt=""/>
          </Link>
        </td>
      </tr>
    );
  }
}

TableRow.propTypes = {
  data: PropTypes.object.isRequired
};

export default Polls;
