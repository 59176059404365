import React, {Component} from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import Steps from './Steps';

const requiredFields = [
  'category',
  'productName',
  'brand',
  'amount',
  'unit',
  'price',
  'panelSize'
];

class PageOne extends Component {
  constructor (props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
  }

  handleNext () {
    const {input, nextPage} = this.props;
    const missingFields = requiredFields.filter(f => !input[f]);
    if (missingFields.length > 0) {
      console.log(missingFields);
      return alert('Bitte vervollständigen Sie Ihre Angaben!');
    }
    nextPage();
  }

  render () {
    const {toggle, onChange, input} = this.props;
    return (
      <>
        <ModalHeader toggle={toggle}>
          Informationen zum Produkttest
          <Steps page={1}/>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="8">
              <Form className="form-horizontal">
                <h4>Produktkategorie</h4>
                <div className="radio-button-group">
                  <label>
                    Lebensmittel
                    <input
                      type="radio"
                      name="category"
                      value="Lebensmittel"
                      checked={input.category === 'Lebensmittel'}
                      onChange={onChange}
                    />
                    <span className="checkmark"/>
                  </label>
                  <label>
                    Kosmetik
                    <input
                      type="radio"
                      name="category"
                      value="Kosmetik"
                      checked={input.category === 'Kosmetik'}
                      onChange={onChange}
                    />
                    <span className="checkmark"/>
                  </label>
                  <label>
                    Sonstiges
                    <input
                      type="radio"
                      name="category"
                      value="Sonstiges"
                      checked={input.category === 'Sonstiges'}
                      onChange={onChange}
                    />
                    <span className="checkmark"/>
                  </label>
                </div>

                <br/>

                <h4>Produktname</h4>
                <small>
                  Genaue Bezeichnung des Produktes z.B. Dinkel-Müsli
                </small>
                <FormGroup>
                  <Input
                    type="text"
                    name="productName"
                    value={input.productName}
                    onChange={onChange}
                  />
                </FormGroup>

                <h4>Marke</h4>
                <small>Hersteller des Produktes</small>
                <FormGroup>
                  <Input
                    type="text"
                    name="brand"
                    value={input.brand}
                    onChange={onChange}
                  />
                </FormGroup>

                <h4>Füllmenge</h4>
                <small>
                  Nettomenge des Produktes ohne Gewicht oder Volumen der
                  Verpackung
                </small>
                <Row>
                  <Col xs="6">
                    <FormGroup>
                      <Label htmlFor="input-amount">Menge</Label>
                      <Input
                        required
                        id="input-amount"
                        type="text"
                        name="amount"
                        placeholder="100"
                        value={input.amount}
                        onChange={onChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label htmlFor="input-unit">Einheit</Label>
                      <Input
                        required
                        id="input-unit"
                        type="text"
                        name="unit"
                        placeholder="ml"
                        value={input.unit}
                        onChange={onChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <h4>Endverbraucherpreis</h4>
                <small>Bruttopreis inkl. MwSt.</small>
                <FormGroup>
                  <Input
                    type="text"
                    name="price"
                    value={input.price}
                    onChange={onChange}
                  />
                </FormGroup>

                <h4>Anzahl der Nutzer der Befragung</h4>
                <small>
                  Anzahl der Nutzer kann nicht höher sein als die
                  verfügbaren Test-Produkte
                </small>
                <FormGroup>
                  <Input
                    type="text"
                    name="panelSize"
                    value={input.panelSize}
                    onChange={onChange}
                  />
                </FormGroup>
              </Form>
            </Col>
            <Col className="pt-hint-border" xs="4">
              <p>
                Bitte füllen Sie folgende Felder mit Informationen zu Ihrem
                Produkt aus, damit wir Ihnen ein unverbindliches Angebot machen
                können.
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleNext}>
            Weiter
          </Button>
        </ModalFooter>
      </>
    );
  }
}

export default PageOne;
