import React from 'react';
import PropTypes from 'prop-types';

function round (n) {
  return (Math.round(n * 10) / 10).toLocaleString('de');
}

const DataTable = ({columns, data, showTotals}) => {
  // if we get get a plain array of rows we transform them into the structure
  // created by compileDataset
  if (Array.isArray(data)) {
    const labels = data.map(row => row[0]);
    const set = {
      data: data.map(d => [d[1], d[2]])
    };
    data = {labels, datasets: [set]};
  }

  const {datasets, labels} = data;

  return (
    <table className="table table-bordered">
      <thead className="thead-default">
        <tr>
          {columns.map(c => <th key={c}>{c}</th>)}
        </tr>
      </thead>
      {datasets.map((set, i) => (
        <tbody key={`dataset-${i + 1}`}>
          {set.data.map((d, i) => (
            <tr key={`${set.label}_${i + 1}`}>
              {Object.prototype.hasOwnProperty.call(set, 'label') &&
                <td>{set.label}</td>}
              <td>{labels[i]}</td>
              <td>{d[0]}</td>
              <td>{round(d[1])}</td>
            </tr>
          ))}
          {showTotals && (
            <tr>
              <td colSpan={
                Object.prototype.hasOwnProperty.call(set, 'label') ? 2 : 1
              }/>
              <td>{set.data.reduce((sum, d) => sum + d[0], 0)}</td>
              <td>{round(set.data.reduce((sum, d) => sum + d[1], 0))}</td>
            </tr>
          )}
        </tbody>
      ))}
    </table>
  );
};

DataTable.propTypes = {
  // column headers
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  // a data structure created by compileDataset odr a plain array of rows
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.array),
    PropTypes.shape({
      labels: PropTypes.array.isRequired,
      datasets: PropTypes.array.isRequired
    })
  ]).isRequired,
  showTotals: PropTypes.bool
};

DataTable.defaultProps = {
  showTotals: true
};

export default DataTable;
