import React from 'react';
import PropTypes from 'prop-types';
import isMultiQuestion from '../../functions/isMultiQuestion';
import socioProps from '../../socioProps';
import GroupedBar from '../Charts/GroupedBar';
import ChartDownloader from '../ChartDownloader';
import CrossTabDropdown from './CrossTabDropdown';
import DataTable from './DataTable';
import AgeSlider from './AgeSlider';
import DataDownloader from './DataDownloader';

// sociodemographic properties to include in menu
const include = [
  'gender',
  'citySize',
  'organicConsumptionFrequency',
  'income',
  'levelOfEducation',
  'diet',
  'ageGroup'
];

const socioOptions = include.map(p => ({
  value: p,
  label: socioProps[p].questionText
}));

const CrossTab = ({
  dataSet,
  question,
  groupQuestion,
  groupOpts,
  ageRange,
  onAgeSelect,
  onGroupQuestionSelect,
  onSocioSelect
}) => {
  const isMulti = isMultiQuestion(question);

  const columns = [
    onSocioSelect ? 'Soziodemographische Variable' : 'Variable',
    'Antwortoptionen',
    'Anzahl' + (isMulti ? ' Antworten mit Erwähnungen' : ''),
    'Anteil in Prozent'
  ];

  return (
    <div className="secondTab">
      {(!onSocioSelect && groupOpts) && (
        <CrossTabDropdown
          options={groupOpts}
          value={groupQuestion._id}
          onChange={onGroupQuestionSelect}
        />
      )}
      {onSocioSelect && (
        <CrossTabDropdown
          options={socioOptions}
          value={groupQuestion.socioDataType}
          onChange={onSocioSelect}
        />
      )}
      <div className="chart-controls">
        <AgeSlider
          ageRange={ageRange}
          onAgeSelect={onAgeSelect}
        />
      </div>
      <GroupedBar data={dataSet} question={question}/>
      <div className="mt-4">
        <DataTable data={dataSet} showTotals={!isMulti} {...{columns}}/>
      </div>
      <div className="download-buttons">
        <ChartDownloader/>
        <DataDownloader data={dataSet} {...{columns}}/>
      </div>
    </div>
  );
};

CrossTab.propTypes = {
  dataSet: PropTypes.shape({
    labels: PropTypes.array.isRequired,
    datasets: PropTypes.array.isRequired
  }).isRequired,
  question: PropTypes.object.isRequired,
  groupQuestion: PropTypes.object.isRequired,
  groupOpts: PropTypes.array,
  ageRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  onAgeSelect: PropTypes.func.isRequired,
  onGroupQuestionSelect: PropTypes.func,
  onSocioSelect: PropTypes.func
};

CrossTab.defaultProps = {
  onGroupQuestionSelect: null,
  onSocioSelect: null,
  groupOpts: null
};

export default CrossTab;
