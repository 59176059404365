import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {Badge, Nav, NavItem, NavLink as RsNavLink} from 'reactstrap';
import classNames from 'classnames';
import nav from './_nav';

class Sidebar extends Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.activeRoute = this.activeRoute.bind(this);
    this.hideMobile = this.hideMobile.bind(this);
  }

  handleClick (e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  activeRoute (routeName, props) {
    return props.location.pathname.indexOf(routeName) > -1
      ? 'nav-item nav-dropdown open'
      : 'nav-item nav-dropdown';
  }

  hideMobile () {
    if (document.body.classList.contains('sidebar-mobile-show')) {
      document.body.classList.toggle('sidebar-mobile-show');
    }
  }

  render () {
    const {props} = this;

    // badge addon to NavItem
    const badge = badge => {
      if (badge) {
        const classes = classNames(badge.class);
        return (
          <Badge className={classes} color={badge.variant}>{badge.text}</Badge>
        );
      }
    };

    // simple wrapper for nav-title item
    const wrapper = item => {
      return (
        item.wrapper && item.wrapper.element
          ? React.createElement(
            item.wrapper.element, item.wrapper.attributes, item.name
          )
          : item.name
      );
    };

    // nav list section title
    const title = (title, key) => {
      const classes = classNames('nav-title', title.class);
      return (<li key={key} className={classes}>{wrapper(title)} </li>);
    };

    // nav list divider
    const divider = (divider, key) => {
      const classes = classNames('divider', divider.class);
      return (<li key={key} className={classes}/>);
    };

    // nav label with nav link
    const navLabel = (item, key) => {
      const classes = {
        item: classNames('hidden-cn', item.class),
        link: classNames('nav-label', item.class ? item.class : ''),
        icon: classNames(
          item.icon ? item.icon : 'fa fa-circle',
          item.label.variant ? `text-${item.label.variant}` : '',
          item.label.class ? item.label.class : ''
        )
      };
      return (
        navLink(item, key, classes)
      );
    };

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames(
          'nav-link',
          item.variant ? `nav-link-${item.variant}` : ''
        ),
        icon: classNames(item.icon)
      };
      return (
        navLink(item, key, classes)
      );
    };

    // nav link
    const navLink = (item, key, classes) => {
      const url = item.url ? item.url : '';
      return (
        /* eslint-disable max-len */
        <NavItem key={key} className={classes.item}>
          { isExternal(url)
            ? <RsNavLink active href={url} className={classes.link}><i className={classes.icon}/><span>{item.name}</span>{badge(item.badge)}</RsNavLink>
            : <NavLink to={url} className={classes.link} activeClassName="active" onClick={this.hideMobile}><i className={classes.icon}/><span>{item.name}</span>{badge(item.badge)}</NavLink>
          }
        </NavItem>
        /* eslint-enable max-len */
      );
    };

    // nav dropdown
    const navDropdown = (item, key) => {
      return (
        // FIXME
        /* eslint-disable jsx-a11y/anchor-is-valid */
        <li key={key} className={this.activeRoute(item.url, props)}>
          <a
            href="#"
            className="nav-link nav-dropdown-toggle"
            onClick={this.handleClick}
          >
            <i className={item.icon}/>
            <span>
              {item.name}
            </span>
          </a>
          <ul className="nav-dropdown-items">
            {navList(item.children)}
          </ul>
        </li>
        /* eslint-enable jsx-a11y/anchor-is-valid */
      );
    };

    // nav type
    const navType = (item, idx) =>
      item.title ? title(item, idx)
        : item.divider ? divider(item, idx)
          : item.label ? navLabel(item, idx)
            : item.children ? navDropdown(item, idx)
              : navItem(item, idx);

    // nav list
    const navList = items => {
      return items.map((item, index) => navType(item, index));
    };

    const isExternal = url => {
      const link = url ? url.substring(0, 4) : '';
      return link === 'http';
    };

    // sidebar-nav root
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <Nav>
            {navList(nav.items)}
          </Nav>
        </nav>
      </div>
    );
  }
}

export default Sidebar;
