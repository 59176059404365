import React from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import Steps from './Steps';

const PageThree = props => {
  const {toggle, nextPage, previousPage, onChange, input} = props;
  return (
    <>
      <ModalHeader toggle={toggle}>
        Angebot anfordern & nächste Schritte
        <Steps page={3}/>
      </ModalHeader>
      <ModalBody>
        <h2>Überblick der nächsten Schritte</h2>
        <div className="nextStepsContainer">
          <div className="icon">
            <img src="/img/ptrequest/next-step-1.svg" alt=""/>
            <div className="legend">
              <div className="header">
                Angebot von Pollion per E-Mail
              </div>
              <div className="text">
                Sie erhalten innerhalb der nächsten 3 Tage ein unverbindliches
                Angebot zu Ihrem Produkttest an Ihre E-Mail Adresse.
              </div>
            </div>
          </div>
          <div className="arrow">
            <img src="/img/ptrequest/arrow.svg" alt=""/>
          </div>
          <div className="icon">
            <img src="/img/ptrequest/next-step-2.svg" alt=""/>
            <div className="legend">
              <div className="header">
                Abstimmung zum Produkttest
              </div>
              <div className="text">
                Gemeinsam besprechen wir alle offenen Fragen. Wenn wir Sie
                überzeugt haben, freuen wir uns über Ihre Zusage.
              </div>
            </div>
          </div>
          <div className="arrow">
            <img src="/img/ptrequest/arrow.svg" alt=""/>
          </div>
          <div className="icon">
            <img src="/img/ptrequest/next-step-3.svg" alt=""/>
            <div className="legend">
              <div className="header">
                Versand der Test-Produkte
              </div>
              <div className="text">
                Sie senden uns die zu testenden Produkte per Post an unsere
                Adresse an Pollion GmbH, Greifswalder Straße 30, 10405 Berlin.
              </div>
            </div>
          </div>
          <div className="arrow">
            <img src="/img/ptrequest/arrow.svg" alt=""/>
          </div>
          <div className="icon">
            <img src="/img/ptrequest/next-step-4.svg" alt=""/>
            <div className="legend">
              <div className="header">
                Wir kümmern uns um die Logistik
              </div>
              <div className="text">
                Pollion verpackt Ihre Produkte und versendet diese an das
                Test-Panel mit dem Fragebogen.
                <br/>
                <br/>
              </div>
            </div>
          </div>
          <div className="arrow">
            <img src="/img/ptrequest/arrow.svg" alt=""/>
          </div>
          <div className="icon">
            <img src="/img/ptrequest/next-step-5.svg" alt=""/>
            <div className="legend">
              <div className="header">
                Schnelle Umfrage-Ergebnisse
              </div>
              <div className="text">
                Innerhalb von 14 Tagen melden wir uns bei Ihnen mit den
                Umfrage-Ergebnissen.
                <br/>
                <br/>
              </div>
            </div>
          </div>
        </div>
        <Row style={{clear: 'both'}} className="pt-4">
          <Col xs="5">
            <h4>Haben Sie noch weitere Fragen an uns?</h4>
            <FormGroup>
              <Input
                type="textarea"
                name="questions"
                value={input.questions}
                onChange={onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={previousPage}>
          Zurück
        </Button>
        <Button color="primary" onClick={nextPage}>
          Angebot anfordern
        </Button>
      </ModalFooter>
    </>
  );
};

export default PageThree;
