import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import Footer from '../components/Footer';

import Auth from '../Auth';
import Analytics from '../views/Analytics';
import Polls from '../views/Polls';
import Panelbook from '../views/Panelbook';
import Dashboard from '../views/Dashboard';

class Full extends Component {
  render () {
    return Auth.isAuthenticated() ? (
      <div className="app">
        <Header/>
        <div className="app-body">
          <Sidebar {...this.props}/>
          <main className="main">
            <Container fluid>
              <Switch>
                <Route path="/dashboard" name="Dashboard" component={Dashboard}/>
                <Route exact path="/polls" name="Alle Umfragen" component={Polls}/>
                <Route path="/biopinio/:pollId" component={Analytics}/>
                <Route exact path="/panelbook" name="Panelbook" component={Panelbook}/>
                <Redirect from="/" to="/dashboard"/>
              </Switch>
            </Container>
          </main>
        </div>
        <Footer/>
      </div>
    ) : (
      <Redirect
        to={{pathname: '/login', state: {from: this.props.location}}}
      />
    );
  }
}

export default Full;
