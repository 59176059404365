import {
  BIRTH_YEAR_QUESTION_ID,
  MIN_AGE,
  MAX_AGE,
  newStartpollDate
} from '../globalVariables';

/*
 * filter submissions according to an age range
 */
function filterByAge (submissions, minAge = MIN_AGE, maxAge = MAX_AGE) {
  if (minAge === MIN_AGE && maxAge === MAX_AGE) {
    return submissions;
  }

  // date of first answer
  const startDate = new Date(Math.min(...submissions.map(s => s.creationTime)));
  // year to base age calculation on
  const baseYear = startDate.getFullYear();

  const maxBirthYear = baseYear - minAge;
  const minBirthYear = baseYear - maxAge;

  if (startDate > newStartpollDate) {
    submissions = submissions.filter(s => {
      return s.birthYear <= maxBirthYear && s.birthYear >= minBirthYear;
    });
  } else {
    submissions = submissions.filter(s => {
      return (
        s[BIRTH_YEAR_QUESTION_ID] <= maxBirthYear &&
        s[BIRTH_YEAR_QUESTION_ID] >= minBirthYear
      );
    });
  }
  return submissions;
}
export default filterByAge;
