import React from 'react';

const stepLabels = [
  'Informationen zum Produkttest',
  'Vorschau des Fragebogens',
  'Angebot anfordern & nächste Schritte'
];

const Steps = props => {
  const {page} = props;

  const steps = stepLabels.map((label, i) => {
    const n = i + 1;
    const className = `step ${page === n ? 'active' : page > n ? 'done' : ''}`;
    return (
      <div key={`step-${n}`} className={className}>
        <span>{n}</span>
        <div className="label">{label}</div>
      </div>
    );
  });

  return (
    <div className="steps">{steps}</div>
  );
};

export default Steps;
