import React, {Component} from 'react';

class Footer extends Component {
  render () {
    return (
      <footer className="app-footer">
        <span>
          <a href="https://biopinio.de">Biopinio</a>{' '}
          &copy; {(new Date()).getFullYear()}
        </span>
      </footer>
    );
  }
}

export default Footer;
