function clipAnsweroptionText (text) {
  if (text.length > 30) {
    let answerOptionNew = [];
    const answerOptionSplit = text.split(' ');
    let charCount = 0;
    for (let i = 0; i < answerOptionSplit.length; i++) {
      charCount = answerOptionSplit[i].length + 1 + charCount;
      if (charCount < 30) {
        answerOptionNew = answerOptionNew + answerOptionSplit[i] + ' ';
      } else {
        charCount = 0;
        answerOptionNew = answerOptionNew + answerOptionSplit[i] + '##';
      }
    }
    return answerOptionNew;
  }
  return text;
}

export default clipAnsweroptionText;
