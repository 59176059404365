import socioProps from '../socioProps';

/*
 * get question object with the specified id
 */
function getQuestion (questions, id) {
  // If the passed ID is a socioDataType we emulate a socioData question object.
  // We need this to be able to show sociodemographic questions in the first
  // tab.
  if (socioProps[id]) {
    return {
      type: 'socioData',
      socioDataType: id,
      text: socioProps[id].questionText
    };
  }
  for (let i = 0; i < questions.length; i++) {
    if (questions[i]._id === id) {
      return questions[i];
    }
  }
}
export default getQuestion;
