import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Radar} from 'react-chartjs-2';
import {Button, Col, Popover, PopoverBody, Row} from 'reactstrap';

const segmentTitles = {
  food: 'Lebensmittel',
  cosmetics: 'Kosmetik',
  other: 'Sonstiges'
};

const cardChartOpts = {
  scale: {
    ticks: {
      max: 100,
      min: 0,
      fontSize: 14,
      display: false
    },
    pointLabels: {
      fontSize: 14
    }
  },
  tooltips: {
    mode: 'label',
    callbacks: {
      label (tooltipItem, data) {
        let value =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        if (tooltipItem.index === 6) {
          value = (value * 2) - 100;
        }
        const {label} = data.datasets[tooltipItem.datasetIndex];
        return label + ': ' + Math.round(value) + '%';
      }
    }
  },
  legend: {
    position: 'top',
    labels: {
      fontSize: 14
    }
  }
};

const plotData = {
  datasets: [
    {
      label: 'Durchschnittswert',
      backgroundColor: 'transparent',
      borderColor: '#ffbf3c',
      pointRadius: 3,
      pointBackgroundColor: '#ffbf3c',
      pointBorderColor: '#ffbf3c',
      pointHoverBackgroundColor: '#ffbf3c',
      pointHoverBorderColor: '#ffbf3c',
      data: []
    },
    {
      label: 'Ihre Werte',
      backgroundColor: '#3EAB3760',
      borderColor: '#3EAB37',
      pointRadius: 5,
      pointBackgroundColor: '#3EAB37',
      pointBorderColor: '#3EAB37',
      pointHoverBackgroundColor: '#3EAB37',
      pointHoverBorderColor: '#3EAB37',
      data: []
    }
  ]
};

const ProductCheckRadar = ({
  marketSegmentBenchmarks,
  customerBenchmarks,
  marketSegment
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  let marketSegmentData = [];
  let customerData = [];

  if (marketSegmentBenchmarks.taste) {
    marketSegmentData = [
      marketSegmentBenchmarks.total,
      marketSegmentBenchmarks.taste,
      marketSegmentBenchmarks.texture,
      marketSegmentBenchmarks.design,
      marketSegmentBenchmarks.appearance,
      marketSegmentBenchmarks.pricePerformanceRatio,
      (marketSegmentBenchmarks.nps + 100) / 2
    ];
  } else if (marketSegmentBenchmarks.effect) {
    marketSegmentData = [
      marketSegmentBenchmarks.total,
      marketSegmentBenchmarks.effect,
      marketSegmentBenchmarks.texture,
      marketSegmentBenchmarks.design,
      marketSegmentBenchmarks.appearance,
      marketSegmentBenchmarks.pricePerformanceRatio,
      (marketSegmentBenchmarks.nps + 100) / 2
    ];
  }
  if (customerBenchmarks.taste) {
    plotData.labels = [
      'Insgesamt',
      'Geschmack',
      'Konsistenz',
      'Design',
      'Aussehen',
      'Preis-Leistungs-Verhältnis',
      'Net Promoter Score'
    ];
    customerData = [
      customerBenchmarks.total,
      customerBenchmarks.taste,
      customerBenchmarks.texture,
      customerBenchmarks.design,
      customerBenchmarks.appearance,
      customerBenchmarks.pricePerformanceRatio,
      (customerBenchmarks.nps + 100) / 2
    ];
  } else if (customerBenchmarks.effect) {
    plotData.labels = [
      'Insgesamt',
      'Wirkung',
      'Konsistenz',
      'Design',
      'Aussehen',
      'Preis-Leistungs-Verhältnis',
      'Net Promoter Score'
    ];
    customerData = [
      customerBenchmarks.total,
      customerBenchmarks.effect,
      customerBenchmarks.texture,
      customerBenchmarks.design,
      customerBenchmarks.appearance,
      customerBenchmarks.pricePerformanceRatio,
      (customerBenchmarks.nps + 100) / 2
    ];
  }

  plotData.datasets[0].data = marketSegmentData;
  plotData.datasets[1].data = customerData;

  return (
    <div>
      <div className="radar-container">
        <Radar data={plotData} options={cardChartOpts}/>
      </div>
      <Row className="pt-4">
        <Col>
          <small>
            basierend auf allen bisherigen Produkttests
            {segmentTitles[marketSegment] &&
              ` im Bereich ${segmentTitles[marketSegment]}`}
          </small>
        </Col>
        <Col>
          <Popover
            placement="bottom"
            isOpen={popoverOpen}
            target="radar-legend"
            toggle={() => setPopoverOpen(!popoverOpen)}
          >
            <PopoverBody>
              <ul>
                <li>
                  Net Promoter Score: Kategorie 9/10 (%) − Kategorie{' '}
                  6 oder geringer (%)
                </li>
                <li>restliche Variablen: Top2-Wert in %</li>
              </ul>
            </PopoverBody>
          </Popover>
          <Button
            outline
            id="radar-legend"
            color="secondary"
            size="sm"
            onClick={() => setPopoverOpen(!popoverOpen)}
          >
            Legende
          </Button>
        </Col>
      </Row>
    </div>
  );
};

ProductCheckRadar.propTypes = {
  marketSegmentBenchmarks: PropTypes.object.isRequired,
  customerBenchmarks: PropTypes.object.isRequired,
  marketSegment: PropTypes.oneOf(['food', 'cosmetics', 'other'])
};

ProductCheckRadar.defaultProps = {
  marketSegment: null
};

export default ProductCheckRadar;
