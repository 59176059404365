import React, {Component} from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown
} from 'reactstrap';

import Auth from '../Auth';
import {apiUrl} from '../config';

class AccountDropdown extends Component {
  constructor (props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      user: null
    };
  }

  componentWillMount () {
    const url = `${apiUrl}/customeraccounts/me`;
    const opts = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    };
    fetch(url, opts)
      .then(response => {
        if (!response.ok) {
          if (Auth.isAuthenticated()) {
            this.handleLogout();
          }
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        this.setState({user: data});
      })
      .catch(err => {
        console.log('cannot get user data', err);
      });
  }

  handleLogout () {
    Auth.signout(err => {
      if (err) {
        console.log(err);
      }
      document.location.href = '/';
    });
  }

  toggle () {
    this.setState(prevState => ({dropdownOpen: !prevState.dropdownOpen}));
  }

  render () {
    const {dropdownOpen, user} = this.state;
    return user ? (
      <Dropdown nav isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <span className="userIcon">
            <img src="/img/user-icon.svg" alt=""/>
          </span>
          {' '}
          <span className="userName">{user.name}</span>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong>{user ? user.customername : '...'}</strong>
          </DropdownItem>
          <DropdownItem onClick={this.handleLogout}>
            <i className="fa fa-lock"/> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    ) : null;
  }
}

export default AccountDropdown;
