/*
 * Socio-demographic properties with labels (taken from pollion-api) and
 * question texts.
 */

// This is mostly the same data structure as socio-data.js in
// pollion-backoffice. It would be ideal to turn it into a package that can be
// shared between pollion projects.

const years = ['not answered'];
for (let i = 2010; i > 1919; i--) {
  years.push(String(i));
}

const socioProps = {
  gender: {
    label: 'Gender',
    question: 'What is your gender?',
    questionText: 'Welches Geschlecht?',
    values: [
      'female',
      'male',
      'diverse',
      'not answered'
    ]
  },
  birthYear: {
    label: 'Birth Year',
    question: 'What year were you born in?',
    questionText: 'Welches Geburtsjahr?',
    compact: true, // used in pollion-backoffice
    values: years
  },
  citySize: {
    label: 'City Size',
    question: 'How many inhabitants has the city in which you live?',
    questionText: 'Größe des Wohnorts?',
    values: [
      'less than 5000',
      '5000 - 19999',
      '20000 - 99999',
      '100000 - 1000000',
      'more than 1000000',
      'not answered'
    ]
  },
  organicConsumptionFrequency: {
    label: 'Frequency of Organic Consumption',
    question: 'How often do you consume organic products?',
    questionText: 'Wie häufig BIO-Konsum?',
    values: [
      'daily',
      'weekly',
      'monthly',
      'less than monthly',
      'never',
      'not answered'
    ]
  },
  diet: {
    label: 'Diet',
    question: 'How would you describe your diet?',
    questionText: 'Welcher Ernährungsstil?',
    values: [
      'omnivore',
      'vegetarian',
      'vegan',
      'flexitarian',
      'pescetarian',
      'fruitarian',
      'raw foodist',
      'paleo',
      'other'
    ]
  },
  hasChildren: {
    label: 'Children',
    question: 'Do you have children under 18 years?',
    questionText: 'Hast Du Kinder unter 18 Jahren?',
    values: [
      'yes',
      'no'
    ]
  },
  ageOfChildren: {
    label: 'Age of Children',
    question: 'How old are your children? (If you have more than one child ' +
              'choose all respective ages.)',
    questionText: 'Wie alt sind Deine Kinder?',
    values: [
      'under 1',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17'
    ]
  },
  numberOfHouseholdMembers: {
    label: 'Household Members',
    question: 'How many people live in your household including yourself?',
    questionText: 'Wie viele Personen im Haushalt?',
    values: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10 or more'
    ]
  },
  income: {
    label: 'Income',
    question: 'What is your monthly net household income? (The net household ' +
              'income is the combined gross income of all members of your ' +
              'household after taxes.)',
    questionText: 'Welches Einkommen?',
    values: [
      'less than €1000',
      '€1000 - €1500',
      '€1500 - €2000',
      '€2000 - €2500',
      '€2500 - €3000',
      '€3000 - €4000',
      '€4000 - €5500',
      '€5500 - €10000',
      'more than €10000',
      'not answered'
    ]

  },
  homeRegion: {
    label: 'Region',
    question: 'Which German or Austrian state or which region do you live ' +
              'in? (principal residence)',
    questionText: 'Welche Region?',
    values: [
      'D-Baden-Württemberg',
      'D-Bayern',
      'D-Berlin',
      'D-Brandenburg',
      'D-Bremen',
      'D-Hamburg',
      'D-Hessen',
      'D-Mecklenburg-Vorpommern',
      'D-Niedersachsen',
      'D-Nordrhein-Westfalen',
      'D-Rheinland-Pfalz',
      'D-Saarland',
      'D-Sachsen',
      'D-Sachsen-Anhalt',
      'D-Schleswig-Holstein',
      'D-Thüringen',
      'AT-Burgenland',
      'AT-Kärnten',
      'AT-Niederösterreich',
      'AT-Oberösterreich',
      'AT-Salzburg',
      'AT-Steiermark',
      'AT-Tirol',
      'AT-Vorarlberg',
      'AT-Wien',
      'Schweiz',
      'Luxemburg',
      'Südtirol',
      'other'
    ]
  },
  levelOfEducation: {
    label: 'Education',
    question: 'What is your level of education?',
    questionText: 'Welcher Ausbildungsabschluss?',
    values: [
      'nodegree',
      'lower',
      'upper',
      'vocational',
      'bachelor',
      'master',
      'PhD',
      'other',
      'not answered'
    ]
  },
  ageGroup: {
    label: 'Age Group',
    question: 'How old are you?',
    questionText: 'Welche Altersgruppe?',
    values: [
      '18 - 29',
      '30 - 39',
      '40 - 49',
      '50+',
      'not answered'
    ]
  }
};

export default socioProps;
