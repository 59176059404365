import 'whatwg-fetch';
import {apiUrl} from './config';

const Auth = {
  isAuthenticated () {
    const sid = localStorage.getItem('sessionId');
    return Boolean(sid);
  },

  authenticate (username, password, cb) {
    const url = `${apiUrl}/customeraccounts/login`;
    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({username, password})
    };
    fetch(url, opts)
      .then(response => {
        console.log(response);
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        localStorage.setItem('sessionId', data.id);
        cb(null, data);
      })
      .catch(err => {
        console.log('login failed', err);
        cb(new Error('login failed'));
      });
  },

  signout (cb) {
    const url = `${apiUrl}/customeraccounts/logout`;
    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      credentials: 'include'
    };
    fetch(url, opts)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        localStorage.removeItem('sessionId');
        cb(null, data);
      })
      .catch(err => {
        console.log('logout failed', err);
        cb(new Error('logout failed'));
      });
  },

  fetchUser (cb) {
    if (!this.isAuthenticated()) {
      return cb(null, null);
    }
    const url = `${apiUrl}/customeraccounts/me`;
    const opts = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    };
    fetch(url, opts)
      .then(response => {
        if (!response.ok) {
          if (this.isAuthenticated()) {
            this.signout((err, data) => {
              if (err) {
                console.log(err);
              }
              console.log(data);
            });
          }
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(user => {
        cb(null, user);
      })
      .catch(err => {
        console.log('cannot get user data');
        cb(err);
      });
  }
};

export default Auth;
