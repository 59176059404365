import socioProps from '../socioProps';
import translateAnswer from './translateAnswer';

/*
 * provide default translated sociodemographic answer options
 */
function getSociodemographicQuestionAnsweroptions (socioDataType) {
  const prop = socioProps[socioDataType];
  if (prop) {
    return prop.values.map(v => translateAnswer(v));
  }
  return [];
}
export default getSociodemographicQuestionAnsweroptions;
