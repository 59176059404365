/*
 * Map startpoll question ID to socioDataType.
 */

import socioProps2questions from '../socioProps2questions';

const map = Object.keys(socioProps2questions).reduce((m, p) => {
  socioProps2questions[p].forEach(id => {
    m[id] = p;
  });
  return m;
}, {});

function mapSocioQuestionId (id) {
  return map[id];
}
export default mapSocioQuestionId;
