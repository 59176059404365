import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {ListGroup, ListGroupItem} from 'reactstrap';
import queryString from 'query-string';
import parseCommentQuery from '../../functions/parseCommentQuery';

/**
 * This function fixes some legacy markup tailored to the shiny dashboard in
 * the commentConclusion HTML snippet by:
 * - removing some redundant UI elements (heading, start link)
 * - rewriting hrefs pointing to shiny dahsboard URLs
 *
 * FIXME: once we retire the shiny dashboard these fixes should be made
 * permanent in the database adding a migration to the pollion-polls service.
 */
function fixConclusion (htmlSnippet) {
  // remove start link
  let html = htmlSnippet.replace(
    /(<br>\s*)*<a href=[^>]+>Hier klicken, um die Präsentation zu starten<\/a>/,
    ''
  );
  // remove headline referring to list of comments
  html = html.replace(
    // eslint-disable-next-line max-len
    /(<br>\s*)*<b>\s*<u>\s*Übersicht der Kommentare\s*(<\/b>\s*<\/u>|<\/u>\s*<\/b>)\n(<br>)*/,
    ''
  );
  // transform other headlines to semantic markup
  html = html.replace(
    /(<br>\s*)*<b>\s*<u>\s*([^<]+)\s*(<\/b>\s*<\/u>|<\/u>\s*<\/b>)\s*(<br>)*/g,
    '\n<h4>$2</h4>\n'
  );
  // rewrite hrefs
  html = html.replace(
    /href=['"]\/poll\/\?(poll_id=([^&]+)[^'"]+)['"]/g,
    (match, qryString, pollId) => {
      const query = parseCommentQuery(qryString);
      return `href="./${pollId}?${queryString.stringify(query)}"`;
    }
  );
  return html;
}

class ConclusionPanel extends Component {
  render () {
    const {poll} = this.props;

    if (!poll.commentConclusion && poll.comments.length === 0) {
      return null;
    }

    const commentItems = poll.comments.filter(
      // skip comments without html text
      // FIXME: why would there be comments without html text?
      comment => Boolean(comment.html)
    ).map(comment => {
      const query = parseCommentQuery(comment.urlQuery);
      const commentLink = `./${poll._id}?${queryString.stringify(query)}`;

      // remove back link
      const html = comment.html.replace(
        /(<br>\s*)*<a href=[^>]+>Zurück zur Übersicht<\/a>/
      );

      /* eslint-disable react/no-danger */
      return (
        <ListGroupItem key={comment._id} className="commentItem">
          <Link to={commentLink}>
            <span className="commentContainer">
              <span dangerouslySetInnerHTML={{__html: html}}/>
            </span>
          </Link>
        </ListGroupItem>
      );
      /* eslint-enable react/no-danger */
    });

    const conclusion = fixConclusion(poll.commentConclusion);

    /* eslint-disable react/no-danger */
    return (
      <div className="panel panel-default conclusion">
        <div
          className="comment-conclusion"
          dangerouslySetInnerHTML={{__html: conclusion}}
        />
        <h4>Kommentare zur Umfrage</h4>
        <ListGroup className="conclusion">
          {commentItems}
        </ListGroup>
      </div>
    );
    /* eslint-enable react/no-danger */
  }
}

ConclusionPanel.propTypes = {
  poll: PropTypes.object.isRequired
};

export default ConclusionPanel;
