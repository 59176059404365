import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Greeting extends Component {
  render () {
    const localTimeHour = new Date().getHours();
    const {name} = this.props;
    const salutation =
      localTimeHour > 5 && localTimeHour < 12
        ? 'Guten Morgen'
        : localTimeHour > 11 && localTimeHour < 17
          ? 'Guten Tag'
          : 'Guten Abend';

    return name ? (
      <h1>
        {salutation}, {name}
      </h1>
    ) : <h1>{salutation}</h1>;
  }
}

Greeting.propTypes = {
  name: PropTypes.string
};

Greeting.defaultProps = {
  name: null
};

export default Greeting;
