export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      name: 'Alle Umfragen',
      url: '/polls',
      icon: 'icon-pie-chart'
    },
    {
      name: 'Panelbuch',
      url: '/panelbook',
      icon: 'icon-chart'
    }
  ]
};
