import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

// Styles
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './coreui/scss/style.scss';
// Temp fix for reactstrap
import './coreui/scss/core/_dropdown-menu-right.scss';

// override CoreUI styles
import './App.scss';

// Containers
import Full from './containers/Full';
import Login from './views/Pages/Login';

class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login" component={Login}/>
          <Route path="/" name="Home" component={Full}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
