import React from 'react';
import PropTypes from 'prop-types';
import isMultiQuestion from '../../functions/isMultiQuestion';
import ChartDownloader from '../ChartDownloader';
import AgeSlider from './AgeSlider';
import DataTable from './DataTable';
import DataDownloader from './DataDownloader';
import GraphicsFirstTabMatrix from "./GraphicsFirstTabMatrix";
import GroupedBar from "../Charts/GroupedBar";
import DataTableMatrix from "./DataTableMatrix";

function getTableData(dataSet) {
    const {countData} = dataSet;
    const columns = [
        'Antwort',
        'Frage',
        'Anzahl',
        'Anteil in Prozent'
    ];
    let showTotals = true;
    /**
    columns.push('Anzahl');
    columns.push('Anteil in Prozent');
    let rows = [];
    Object.entries(countData).forEach(array => {
        rows[array[0]] = [];
        dataSet.answerOptions.forEach(answer => {
            if (array[1][answer]) {
                rows[array[0]].push([answer, array[1][answer][0], array[1][answer][1]]);
            }
        });
    });
     */

    return [columns];
}

const FirstTabMatrix = ({dataSet, ageRange, onAgeSelect, question}) => {
    const [columns] = getTableData(dataSet);
    console.log("dataSet", dataSet);

    /**
    let data = [];
    Object.entries(dataSet.countData).forEach(array => {
        data.push({
            answerOptions: dataSet.answerOptions,
            numSubmissions: dataSet.numSubmissions,
            question: dataSet.question,
            cutoffInclusion: 1,
            countData: array[1],
            key: array[0]
        })
    })
     */

    return (
        <div>
            <div className="chart-controls">
                <AgeSlider {...{ageRange, onAgeSelect}}/>
            </div>
                <div>
                    <div className="mt-4 mb-2"><strong>{dataSet['key']}</strong></div>
                    <GroupedBar data={dataSet} question={question}/>
                    <div className="mt-4">
                        <DataTableMatrix {...{columns}} data={dataSet} />
                    </div>
                    <div className="download-buttons">
                        <ChartDownloader/>
                        <DataDownloader data={dataSet} {...{columns}}/>
                    </div>
                </div>
        </div>
    );
};

FirstTabMatrix.propTypes = {
    dataSet: PropTypes.shape({
        countData: PropTypes.object.isRequired,
        cutoffInclusion: PropTypes.number.isRequired,
        numSubmissions: PropTypes.number.isRequired,
        question: PropTypes.object.isRequired,
        answeroptions: PropTypes.array
    }).isRequired,
    ageRange: PropTypes.array.isRequired,
    onAgeSelect: PropTypes.func.isRequired
};

export default FirstTabMatrix;
