function checkBifurcation (question, data, submissions) {
  submissions = submissions || data.submissions;

  // if specific input question between filter question and 'jump-to value', get
  // id of bifurcation question and filter by it
  const bifurcationQuestions = data.poll.questions.filter(x => {
    return (
      x.order !== null &&
      x.jumpToWhenAnswered !== null &&
      question.order > x.order &&
      question.order < x.jumpToWhenAnswered
    );
  });
  if (Object.keys(bifurcationQuestions).length > 0) {
    submissions = submissions.filter(x => {
      return (
        x[
          bifurcationQuestions[
            Object.keys(bifurcationQuestions)[
              Object.keys(bifurcationQuestions).length - 1
            ]
          ]._id
        ] === 'Ja'
      );
    });
  }
  return submissions;
}
export default checkBifurcation;
