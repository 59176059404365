const globalVariables = {
  MIN_AGE: 16,
  MAX_AGE: 90,
  THEME_COLOR: '#3AAA35', // FIXME: should be configured in database
  BIRTH_YEAR_QUESTION_ID: '56e6ae134827574126e5ac8f',
  oldStartpollIncomeQuestionId: 'c0ae6d23a9b3ea2c',
  startpollIncomeQuestionId: '56e6b1294827574126e5ac95',
  newStartpollIncomeQuestionId: '58d12e1316c5278f5f1d41d3',
  oldStartpollEducationQuestionId: '89dd3a6b1f3e69ac',
  startpollEducationQuestionId: '56e6b01e7838a6f524b0fb98',
  newStartpollEducationQuestionId: '58d12cc916c5278f5f1d41d0',
  oldStartpollDietQuestionId: 'a20e87e8b999a835',
  startpollDietQuestionId: '56e69f644827574126e5ac71',
  newStartpollDietQuestionId: '58d127e516c5278f5f1d41c1',
  oldStartpollOrganicConsumptionFrequencyQuestionId: '1896bda912d83a39',
  startpollOrganicConsumptionFrequencyQuestionId: '56e69dbf7838a6f524b0fb64',
  newStartpollOrganicConsumptionFrequencyQuestionId: '58d1277c16c5278f5f1d41be',
  pastapollOrganicConsumptionFrequencyQuestionId: '552cd17a26066cad1505727e',
  fruitSpreadBranchOrganicConsumptionFrequencyQuestionId: 'ae77b062d7612a69',
  fruitSpreadPanelOrganicConsumptionFrequencyQuestionId: 'e60de72a4324394d',
  startpollPanelOrganicConsumptionFrequencyQuestionId:
    '54bfd6f44856e5ff31ac7d65',
  oldStartpollGenderQuestionId: 'f4b91cee8f5dd873',
  startpollGenderQuestionId: '56e6ae077838a6f524b0fb90',
  newStartpollGenderQuestionId: '58d12c7f16c5278f5f1d41cc',
  pastapollGenderQuestionId: '552cd16595e5241c4a5e0231',
  fruitSpreadBranchGenderQuestionId: '1534567e7c9ef8b0',
  fruitSpreadPanelGenderQuestionId: '2ecada10194148ed',
  startpollPanelGenderQuestionId: '54bfd9ef4856e5ff31ac7d70',
  nextOrganicPanelGenderQuestionId: '573f2018e2ab3dbc2969136b',
  oldStartpollCitysizeQuestionId: '8084dfc1b139d88c',
  startpollCitysizeQuestionId: '56e6afb07838a6f524b0fb97',
  newStartpollCitysizeQuestionId: '58d12cbe16c5278f5f1d41cf',
  startpollHavingChildrenQuestionId: '56e6a15f4827574126e5ac78',
  newStartpollHavingChildrenQuestionId: '58d129a116c5278f5f1d41ca',
  startpollAgeChildrenQuestionId: '56e6adf34827574126e5ac8e',
  newStartpollAgeChildrenQuestionId: '58d129b116c5278f5f1d41cb',
  startpollNumberPplHouseholdQuestionId: '56e6a0b74827574126e5ac76',
  newStartpollNumberPplHouseholdQuestionId: '58d129b116c5278f5f1d41cb',
  newStartpollHomeRegionQuestionId: '58d12c9e16c5278f5f1d41ce',
  newStartpollDate: new Date('2016-06-15')
};

module.exports = globalVariables;
