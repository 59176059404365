import React from 'react';
import {Button} from 'reactstrap';

function downloadFromCanvas (canvas) {
  const link = document.createElement('a');
  link.download = 'plot.png';
  link.href = canvas.toDataURL('image/png');
  link.click();
  link.remove();
}

function handleDownload () {
  const svg = document.querySelector('.tab-pane.active svg');
  const canvas = document.querySelector('.tab-pane.active canvas');

  if (svg) {
    const svgString = (new XMLSerializer()).serializeToString(svg);
    const blob = new Blob([svgString], {type: 'image/svg+xml;charset=utf-8'});
    const DOMURL = window.URL || window.webkitURL || window;
    const url = DOMURL.createObjectURL(blob);
    const img = new Image();
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);
      downloadFromCanvas(canvas);
    });
    img.src = url;
  } else if (canvas) {
    downloadFromCanvas(canvas);
  } else {
    console.warn('cannot find svg or canvas for chart');
  }
}

const ChartDownloader = () => {
  return (
    <Button
      className="download-button"
      size="sm"
      onClick={handleDownload}
    >
      Graphik herunterladen
    </Button>
  );
};

export default ChartDownloader;
