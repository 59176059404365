import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

const CrossTabDropdownItem = ({value, label, onChange}) => {
  return (
    <DropdownItem
      value={value}
      onClick={() => onChange(value)}
    >
      {label}
    </DropdownItem>
  );
};

const CrossTabDropdownGroup = ({choices, onChange}) => {
  const choiceItems = choices.map(choice => {
    const {value, label} = choice;
    return (
      <CrossTabDropdownItem
        key={`list-item-${value}`}
        label={label}
        value={value}
        choices={choices}
        onChange={onChange}
      />
    );
  });
  return <div>{choiceItems}</div>;
};

const CrossTabDropdown = ({options, onChange, value}) => {
  const [isOpen, setIsOpen] = useState(false);

  value = value || options[0].value;

  // if the passed value doesn't match any option we fall back to the first
  const selectedOpt =
    options.filter(o => o.value === value)[0] ||
    options[0];

  return (
    <ButtonDropdown
      className="crosstab-dropdown"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <DropdownToggle caret color="secondary">
        {selectedOpt.label}
      </DropdownToggle>
      <DropdownMenu>
        <CrossTabDropdownGroup
          choices={options}
          value={selectedOpt.value}
          onChange={onChange}
        />
      </DropdownMenu>
    </ButtonDropdown>
  );
};

CrossTabDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

CrossTabDropdown.defaultProps = {
  value: null
};

export default CrossTabDropdown;
