import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Card, CardBody} from 'reactstrap';
import {Route, Link} from 'react-router-dom';
import queryString from 'query-string';
import {apiUrl} from '../config';
import socioProps from '../socioProps';
import vars from '../globalVariables';
import Loading from '../components/Loading';
import Tabs from '../components/Analytics/Tabs';
import ConclusionPanel from '../components/Analytics/ConclusionPanel';
import CommentPanel from '../components/Analytics/CommentPanel';
import QuestionDropdown from '../components/Analytics/QuestionDropdown';
import ModalPresentation from '../components/Analytics/ModalPresentation';
import mapSocioQuestionId from '../functions/mapSocioQuestionId';
import filterQuestions from '../functions/filterQuestions';
import setAgeGroup from '../functions/setAgeGroup';
import ProductCheckRadar from '../components/Charts/ProductCheckRadar';

class ScrollToTopOnMount extends Component {
  componentDidMount () {
    window.scrollTo(0, 0);
  }

  render () {
    return null;
  }
}

class Analytics extends Component {
  constructor (props) {
    super(props);

    this.state = {
      pollData: null,
      customerId: null,
      questionId: null,
      socioQuestionId: 'gender',
      ageRange: [vars.MIN_AGE, vars.MAX_AGE],
      activeTab: '1',
      commentsPresent: null,
      marketSegmentBenchmarks: {},
      customerBenchmarks: {}
    };
    this.handleQuestionChange = this.handleQuestionChange.bind(this);
    this.handleAgeSelect = this.handleAgeSelect.bind(this);
    this.handleSocioSelect = this.handleSocioSelect.bind(this);
    this.handleGroupQuestionSelect = this.handleGroupQuestionSelect.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount () {
    this.loadData();
  }

  componentWillReceiveProps (nextProps) {
    this.parseQuery(nextProps.location);
  }

  parseQuery (location) {
    const {commentsPresent, marketSegment} = this.state;
    const query = queryString.parse(location.search);
    const state = {
      socioQuestionId: query.socioquestion || 'gender',
      ageRange: [
        query.minAge
          ? parseInt(query.minAge, 10) || vars.MIN_AGE
          : vars.MIN_AGE,
        query.maxAge ? parseInt(query.maxAge, 10) || vars.MAX_AGE : vars.MAX_AGE
      ]
    };
    if (Object.keys(query).length > 0 || commentsPresent || marketSegment) {
      state.questionId = query.question || null;
    }
    if (query.socioquestion && !socioProps[query.socioquestion]) {
      this.setQuery({
        socioquestion: mapSocioQuestionId(query.socioquestion) || null
      });
    }
    if (query.groupquestion) {
      state.groupQuestionId = query.groupquestion;
    }
    state.activeTab = query.tab || (query.socioquestion ? '2' : '1');
    this.setState(state);
  }

  getUrl (params) {
    const {match, location} = this.props;
    const query = queryString.parse(location.search);
    const newQuery = Object.assign(query, params);
    // strip empty params
    let n = 0;
    Object.keys(newQuery).forEach(k => {
      if (newQuery[k]) {
        n++;
      } else {
        delete newQuery[k];
      }
    });
    let url = `/biopinio/${match.params.pollId}`;
    if (n > 0) {
      url += `?${queryString.stringify(newQuery)}`;
    }
    return url;
  }

  setQuery (params) {
    const {questionId} = this.state;
    const {location, history} = this.props;
    const query = queryString.parse(location.search);
    const newQuery = Object.assign(query, params);
    // strip empty params
    Object.keys(newQuery).forEach(k => {
      if (!newQuery[k]) {
        delete newQuery[k];
      }
    });

    // If the state has a questionId and the query update does not explicitly
    // unset the `question` parameter we add the current questionId to the new
    // query.
    // FIXME: this is a preliminary fix for issue
    // https://github.com/pollion/pollion-cockpit/issues/103. A better solution
    // would be to restructure the component hierachy so that the question ID is
    // passed down to components that need it.
    if (
      questionId &&
      !Object.prototype.hasOwnProperty.call(params, 'question')
    ) {
      newQuery.question = questionId;
    }

    history.replace({search: queryString.stringify(newQuery)});
  }

  loadData () {
    const {match} = this.props;
    const url = `${apiUrl}/customerpollquestionsandanswers?pollid=${
      match.params.pollId
    }`;
    const opts = {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
      credentials: 'include'
    };
    fetch(url, opts)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(pollData => {
        setAgeGroup(pollData.submissions);
        const customerId = pollData.customerid;
        const questions = filterQuestions(pollData.poll.questions);
        const questionId =
          pollData.poll.comments.length === 0 && questions.length > 0
            ? questions[0]._id
            : null;
        const commentsPresent = pollData.poll.comments.length > 0;
        const {marketSegment} = pollData.poll;

        this.setState(
          {
            pollData,
            customerId,
            questions,
            questionId,
            commentsPresent,
            marketSegment
          },
          () => this.parseQuery(window.location)
        );
        const benchmarksUrl = `${apiUrl}/benchmarks/marketsegment/${
          pollData.poll.marketSegment ? pollData.poll.marketSegment : ''
        }`;
        fetch(benchmarksUrl, opts)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(data => {
            this.setState({
              marketSegmentBenchmarks: data
            });
          });

        const customerBenchmarksUrl = `${apiUrl}/benchmarks/customer/${
          customerId
        }/poll/${pollData.poll._id}`;
        fetch(customerBenchmarksUrl, opts)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(data => {
            this.setState({
              customerBenchmarks: data
            });
          });
      })
      .catch(error => {
        console.log('cannot load data', error);
      });
  }

  handleQuestionChange (question) {
    this.setState({questionId: question});
    this.setQuery({question});
  }

  handleAgeSelect (ageRange) {
    const [minAge, maxAge] = ageRange;
    this.setQuery({minAge, maxAge});
  }

  handleSocioSelect (value) {
    const socioquestion = value;
    this.setQuery({socioquestion});
  }

  handleGroupQuestionSelect (value) {
    const groupquestion = value;
    this.setQuery({groupquestion});
  }

  handleTabChange (tab) {
    const {activeTab} = this.state;
    if (activeTab === tab) {
      return;
    }
    this.setQuery({tab});
  }

  renderRadar () {
    const {
      marketSegment,
      questionId,
      marketSegmentBenchmarks,
      customerBenchmarks
    } = this.state;
    if (!marketSegment || questionId) {
      return null;
    }
    return (
      <>
        <h4 className="overview">Übersicht Produkttest</h4>
        <Card>
          <CardBody>
            <ProductCheckRadar {...{
              customerBenchmarks,
              marketSegment,
              marketSegmentBenchmarks
            }}/>
          </CardBody>
        </Card>
      </>
    );
  }

  renderMainPanel () {
    const {
      customerId,
      pollData,
      activeTab,
      questionId,
      socioQuestionId,
      groupQuestionId,
      commentsPresent,
      ageRange
    } = this.state;

    if (!questionId) {
      return (
        <ConclusionPanel poll={pollData.poll}/>
      );
    }

    return (
      <div>
        {commentsPresent &&
          <CommentPanel poll={pollData.poll} questionId={questionId}/>}
        <Route
          render={() => (
            <Tabs
              customerId={customerId}
              pollData={pollData}
              questionId={questionId}
              socioQuestionId={socioQuestionId}
              groupQuestionId={groupQuestionId}
              ageRange={ageRange}
              activeTab={activeTab}
              onTabChange={this.handleTabChange}
              onAgeSelect={this.handleAgeSelect}
              onSocioSelect={this.handleSocioSelect}
              onGroupQuestionSelect={this.handleGroupQuestionSelect}
              {...this.props}
            />
          )}
        />
      </div>
    );
  }

  renderPoll () {
    const {pollData, questionId, questions, marketSegment} = this.state;

    const ids = questions.map(q => q._id);
    const offset = ids.indexOf(questionId);
    const nav = {prev: null, next: null};
    if (offset < ids.length - 1) {
      const nextUrl = this.getUrl({question: ids[offset + 1]});
      nav.next = <Link to={nextUrl}>Zur nächsten Frage</Link>;
    }
    if (offset > 0) {
      const prevUrl = this.getUrl({question: ids[offset - 1]});
      nav.prev = <Link to={prevUrl}>Zur vorherigen Frage</Link>;
    }
    if (offset === 0 && marketSegment) {
      const prevUrl = this.getUrl({question: null});
      nav.prev = <Link to={prevUrl}>Zur Übersicht</Link>;
    }

    return (
      <>
        <h1 className="poll-title">{pollData.poll.title}</h1>
        <Row>
          <Col sm="12">
            <div className="dropdownLabel">Wählen Sie eine Frage aus:</div>
            <QuestionDropdown
              poll={pollData.poll}
              questions={questions}
              questionId={questionId}
              onChange={this.handleQuestionChange}
            />
            <div className="previousQuestion">
              {nav.prev}
            </div>
            <div className="nextQuestion">
              {nav.next}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {this.renderRadar()}
            {this.renderMainPanel()}
          </Col>
        </Row>
        <Row>
          <Col sm="4"/>
          <Col sm="8">
            {!questionId && pollData.poll.comments.length > 0
              ? <ModalPresentation title={pollData.poll.title}/>
              : null
            }
          </Col>
        </Row>
      </>
    );
  }

  render () {
    const {pollData} = this.state;
    return (
      <div className="page poll-page animated fadeIn">
        <ScrollToTopOnMount/>
        {pollData ? this.renderPoll() : <Loading/>}
      </div>
    );
  }
}

Analytics.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Analytics;
