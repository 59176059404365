import globalVariables from './globalVariables';

// This maps sociodemographic properties to the question IDs of startpoll
// questions

const socioProps2questions = {
  income: [
    globalVariables.startpollIncomeQuestionId,
    globalVariables.oldStartpollIncomeQuestionId
  ],
  levelOfEducation: [
    globalVariables.startpollEducationQuestionId,
    globalVariables.oldStartpollEducationQuestionId
  ],
  diet: [
    globalVariables.startpollDietQuestionId,
    globalVariables.oldStartpollDietQuestionId
  ],
  hasChildren: [
    globalVariables.startpollHavingChildrenQuestionId,
    globalVariables.startpollHavingChildrenQuestionId
  ],
  ageOfChildren: [
    globalVariables.startpollAgeChildrenQuestionId,
    globalVariables.startpollAgeChildrenQuestionId
  ],
  numberOfHouseholdMembers: [
    globalVariables.startpollNumberPplHouseholdQuestionId,
    globalVariables.startpollNumberPplHouseholdQuestionId
  ]
};

export default socioProps2questions;
