import React from 'react';
import PropTypes from 'prop-types';
import isMultiQuestion from '../../functions/isMultiQuestion';
import ChartDownloader from '../ChartDownloader';
import GraphicsFirstTab from './GraphicsFirstTab';
import AgeSlider from './AgeSlider';
import DataTable from './DataTable';
import DataDownloader from './DataDownloader';

function getTableData (dataSet) {
  const {countData, answerOptions, numSubmissions, question} = dataSet;
  const columns = ['Antworten'];
  let showTotals = true;
  if (isMultiQuestion(question)) {
    columns.push('Anzahl Antworten mit Erwähnungen');
    columns.push(`Anteil Antworten mit Erwähnungen (N=${numSubmissions})`);
    showTotals = false;
  } else {
    columns.push('Anzahl');
    columns.push('Anteil in Prozent');
  }
  const uniqueAnswers = answerOptions || Object.keys(countData).sort();
  const rows = uniqueAnswers.map(a => {
    return [a, ...countData[a]];
  });

  return [columns, rows, showTotals];
}

const FirstTab = ({dataSet, ageRange, onAgeSelect}) => {
  const [columns, rows, showTotals] = getTableData(dataSet);

  return (
    <div>
      <div className="chart-controls">
        <AgeSlider {...{ageRange, onAgeSelect}}/>
      </div>
      <GraphicsFirstTab {...{dataSet}}/>
      <div className="mt-4">
        <DataTable data={rows} {...{columns, showTotals}}/>
      </div>
      <div className="download-buttons">
        <ChartDownloader/>
        <DataDownloader data={rows} {...{columns}}/>
      </div>
    </div>
  );
};

FirstTab.propTypes = {
  dataSet: PropTypes.shape({
    countData: PropTypes.object.isRequired,
    cutoffInclusion: PropTypes.number.isRequired,
    numSubmissions: PropTypes.number.isRequired,
    question: PropTypes.object.isRequired,
    answeroptions: PropTypes.array
  }).isRequired,
  ageRange: PropTypes.array.isRequired,
  onAgeSelect: PropTypes.func.isRequired
};

export default FirstTab;
