import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

import Auth from '../../Auth';

class Login extends Component {
  constructor (props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      redirectToReferrer: false
    };
  }

  handleChange (e) {
    const state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  handleSubmit () {
    const {username, password} = this.state;
    console.log(this.state);
    Auth.authenticate(username, password, err => {
      if (err) {
        return window.alert('Login failed');
      }
      this.setState({redirectToReferrer: true});
    });
  }

  render () {
    const {from} = this.props.location.state || {from: {pathname: '/'}};
    const {username, password, redirectToReferrer} = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from}/>;
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="4">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>Login</h1>
                    <p className="text-muted">Zugang zu Ihrem Konto</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        name="username"
                        placeholder="Benutzername (Email)"
                        value={username}
                        onChange={e => this.handleChange(e)}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        name="password"
                        placeholder="Passwort"
                        value={password}
                        onChange={e => this.handleChange(e)}
                      />
                    </InputGroup>
                    <Button
                      color="primary"
                      className="px-4"
                      disabled={!username}
                      onClick={() => this.handleSubmit()}
                    >
                      Login
                    </Button>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
