import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Bar, HorizontalBar} from 'react-chartjs-2';

class BarChart extends Component {
  render () {
    const {dataSet, plotData, showValues} = this.props;
    const {answerOptions, question} = dataSet;

    const cardChartOpts = {
      scaleShowValues: true,
      scales: {
        yAxes: [],
        xAxes: []
      },
      legend: {
        display: false
      },
      tooltips: {}
    };

    if (showValues) {
      cardChartOpts.tooltips = {
        enabled: false
      };
      cardChartOpts.animation = {
        duration: 1,
        onComplete () {
          const chartInstance = this.chart;
          const {ctx} = chartInstance;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'center';
          ctx.fillStyle = 'rgba(0, 0, 0, 1)';
          ctx.font = '10pt Arial';

          this.data.datasets.forEach((dataset, i) => {
            const meta = chartInstance.controller.getDatasetMeta(i);
            if (meta.type === 'horizontalBar') {
              meta.data.forEach((bar, index) => {
                const data = Math.round(dataset.data[index]) + ' %';
                ctx.fillText(data, bar._model.x + 20, bar._model.y + 5);
              });
            } else {
              meta.data.forEach((bar, index) => {
                const data = Math.round(dataset.data[index]) + ' %';
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            }
          });
        }
      };
    } else {
      cardChartOpts.tooltips = {
        enabled: true,
        mode: 'label',
        callbacks: {
          label (tooltipItem, data) {
            return Math.round(data.datasets[0].data[tooltipItem.index]) + '%';
          }
        }
      };
      cardChartOpts.animation = {
        duration: 1000,
        onComplete () {}
      };
    }

    // get maximal length of answer option labels
    const maxStringLength = Math.max(...answerOptions.map(x => x.length));
    const axisDesignCategories = [
      {
        ticks: {
          stepSize: 1,
          autoSkip: false
        }
      }
    ];
    const axisDesignValues = [
      {
        ticks: {
          beginAtZero: true,
          callback (value) {
            return value + '%';
          }
        }
      }
    ];

    // use horizontal bar chart in case of more than 12 answer options or if
    // length of any answer option string is greater than 30, except for socio
    if (
      (answerOptions.length > 12) | (maxStringLength > 30) &&
      question.type !== 'socioData'
    ) {
      cardChartOpts.scales.xAxes = axisDesignValues;
      cardChartOpts.scales.yAxes = axisDesignCategories;
      const padding = showValues ? {right: 20, top: 0} : {right: 0, top: 0};
      cardChartOpts.layout = {padding};

      return (
        <div className="bar-chart">
          <HorizontalBar data={plotData} options={cardChartOpts} height={150}/>
        </div>
      );
    }
    cardChartOpts.scales.xAxes = axisDesignCategories;
    cardChartOpts.scales.yAxes = axisDesignValues;
    const padding = showValues ? {right: 0, top: 20} : {right: 0, top: 0};
    cardChartOpts.layout = {padding};

    return (
      <div className="bar-chart">
        <Bar data={plotData} options={cardChartOpts} height={150}/>
      </div>
    );
  }
}

BarChart.propTypes = {
  dataSet: PropTypes.shape({
    countData: PropTypes.object.isRequired,
    cutoffInclusion: PropTypes.number.isRequired,
    numSubmissions: PropTypes.number.isRequired,
    question: PropTypes.object.isRequired,
    answerOptions: PropTypes.array.isRequired
  }).isRequired,
  plotData: PropTypes.object.isRequired,
  showValues: PropTypes.bool
};

BarChart.defaultProps = {
  showValues: false
};

export default BarChart;
