import React, {Component} from 'react';
import {
  Nav,
  NavbarToggler,
  NavbarBrand
} from 'reactstrap';

import AccountDropdown from './AccountDropdown';

class Header extends Component {
  sidebarToggle (e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize (e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle (e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle (e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  render () {
    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon"/>
        </NavbarToggler>
        <NavbarBrand href="/dashboard"/>
        <Nav navbar className="ml-auto mr-3">
          <AccountDropdown/>
        </Nav>
      </header>
    );
  }
}

export default Header;
