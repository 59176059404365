import queryString from 'query-string';

const legacyParams = {
  /* eslint-disable camelcase */
  question_id: 'question',
  socio_question_id: 'socioquestion',
  age_group_min: 'minAge',
  age_group_max: 'maxAge'
  /* eslint-enable camelcase */
};

const legacyValues = {
  /* eslint-disable camelcase */
  socioquestion: {
    age_groups: 'ageGroup'
  }
  /* eslint-enable camelcase */
};

/**
 * This function parses a URL query string into an object. For query strings
 * representing the old dashboard syntax we translate the query to the new
 * parameter names and values using the mappings in legacyParams and
 * legacyValues.
 */
function parseCommentQuery (qString) {
  const query = queryString.parse(qString);
  if (query.poll_id) {
    const transQuery = {};
    Object.keys(legacyParams).forEach(key => {
      if (query[key]) {
        const transKey = legacyParams[key];
        let value =
          key === 'question_id' || key === 'socio_question_id'
          // remove leading 'X' used in legacy queries to accomodate R
            ? query[key].replace(/^X/, '')
            : query[key];

        if (legacyValues[transKey]) {
          value = legacyValues[transKey][value] || value;
        }
        transQuery[transKey] = value;
      }
      // remove question parameter if it doesn't point to an actual question
      if (transQuery.question === 'Zusammenfassung') {
        delete transQuery.question;
      }
    });
    return transQuery;
  }
  return query;
}

export default parseCommentQuery;
