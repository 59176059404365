import React, {Component} from 'react';
import {Button, Modal} from 'reactstrap';
import {apiUrl} from '../../config';
import Auth from '../../Auth';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import PageThree from './PageThree';
import PageFour from './PageFour';

const inputDefaults = {
  category: 'Sonstiges',
  productName: '',
  brand: '',
  amount: '',
  unit: '',
  price: '',
  panelSize: '',
  questions: ''
};

class ProductTestForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modal: false,
      page: 1,
      input: Object.assign({}, inputDefaults)
    };

    this.toggle = this.toggle.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
  }

  toggle () {
    const {modal} = this.state;
    this.setState({
      modal: !modal,
      page: 1,
      input: Object.assign({}, inputDefaults)
    });
  }

  handleInput (event) {
    const {name, value} = event.target;
    this.setState(prevState => {
      const {input} = prevState;
      input[name] = value;
      return {input};
    });
  }

  sendRequest (callback) {
    const {input} = this.state;
    const url = `${apiUrl}/requestquote`;

    Auth.fetchUser((err, user) => {
      if (err) {
        return callback(err);
      }
      const data = Object.assign({
        name: user.name,
        email: user.username,
        customer: user.customername
      }, input);

      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
      };

      fetch(url, opts)
        .then(response => {
          if (!response.ok) {
            console.log(response);
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(data => {
          console.log(data);
          return callback();
        })
        .catch(err => {
          return callback(err);
        });
    });
  }

  nextPage () {
    const {page} = this.state;
    if (page < 4) {
      this.setState({page: page + 1});
    } else {
      this.setState({page: 1});
    }
  }

  renderModalContent () {
    const {page, input} = this.state;
    if (page === 2) {
      return (
        <PageTwo
          toggle={this.toggle}
          nextPage={this.nextPage}
          previousPage={() => this.setState({page: 1})}
          input={input}
        />
      );
    }
    if (page === 3) {
      return (
        <PageThree
          input={input}
          toggle={this.toggle}
          nextPage={() => {
            this.sendRequest(err => {
              if (err) {
                alert('Ein Fehler ist aufgetreten.');
                return;
              }
              this.nextPage();
            });
          }}
          previousPage={() => this.setState({page: 2})}
          onChange={this.handleInput}
        />
      );
    }
    if (page === 4) {
      return (
        <PageFour toggle={this.toggle}/>
      );
    }
    return (
      <PageOne
        input={input}
        toggle={this.toggle}
        nextPage={this.nextPage}
        onChange={this.handleInput}
      />
    );
  }

  render () {
    const {modal} = this.state;
    return (
      <span className="buttonContainer">
        <Button color="primary" onClick={() => this.toggle()}>
          <i className="fa fa-plus"/>{' '}
          Neuen Produkttest anfordern
        </Button>
        <Modal
          className="modal-lg product-test"
          isOpen={modal}
          toggle={this.toggle}
        >
          {this.renderModalContent()}
        </Modal>
      </span>
    );
  }
}

export default ProductTestForm;
